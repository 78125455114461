import { Controller } from "stimulus";

export default class extends Controller {

  updateOptionNames(event) {
  	var variantOptions = []

  	if($('.js-variant-option-1').is('*')) {
      var newVariantName = $('.js-variant-option-1').val();
      var originalVariantName = $('.js-variant-option-1').data('originalname');

      var newVariantNameLabel = $('.js-variant-option-name-1').val();
      var originalVariantNameLabel = $('.js-variant-option-name-1').data('originalname');

  		variantOptions.push(newVariantName);
      $('[data-variant-option-name-original="' + originalVariantName + '"]').html(newVariantName);

      if(newVariantName != originalVariantName || newVariantNameLabel != originalVariantNameLabel){
        $('.js-variant-option-1-warning').show();
      } else {
        $('.js-variant-option-1-warning').hide();
      }
  	}

    if($('.js-variant-option-2').is('*')) {
      var newVariantName = $('.js-variant-option-2').val();
      var originalVariantName = $('.js-variant-option-2').data('originalname');

      var newVariantNameLabel = $('.js-variant-option-name-2').val();
      var originalVariantNameLabel = $('.js-variant-option-name-2').data('originalname');

      variantOptions.push(newVariantName);
      $('[data-variant-option-name-original="' + originalVariantName + '"]').html(newVariantName);

      if(newVariantName != originalVariantName || newVariantNameLabel != originalVariantNameLabel){
        $('.js-variant-option-2-warning').show();
      } else {
        $('.js-variant-option-2-warning').hide();
      }
    }

    if($('.js-variant-option-3').is('*')) {
      var newVariantName = $('.js-variant-option-3').val();
      var originalVariantName = $('.js-variant-option-3').data('originalname');

      var newVariantNameLabel = $('.js-variant-option-name-3').val();
      var originalVariantNameLabel = $('.js-variant-option-name-3').data('originalname');
      variantOptions.push(newVariantName);
      $('[data-variant-option-name-original="' + originalVariantName + '"]').html(newVariantName);


      if(newVariantName != originalVariantName || newVariantNameLabel != originalVariantNameLabel){
        $('.js-variant-option-3-warning').show();
      } else {
        $('.js-variant-option-3-warning').hide();
      }
    }

    var newName = variantOptions.join(" / ");
    $('.js-variant-name-update').html(newName);
  }
}