import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Trigger importMappingAttributeChange on the select
    var select = $(this.element).find('select').trigger('change');
    this.importMappingAttributeChangeColor(select);
  }

  importMappingAttributeChange(event) {
    var select = $(event.target);
    this.importMappingAttributeChangeColor(select);
  }

  importMappingAttributeChangeColor(select) {
    var import_mapping = select.val();

    var row_status = select.parent().parent().find('.js-row-status');

    if(import_mapping == ''){
      row_status.addClass('text-danger');
      row_status.removeClass('text-success');
    } else {
      row_status.removeClass('text-danger');
      row_status.addClass('text-success');
    }
  }
}