import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var formElem = $(this.element);

    var productSelect = formElem.find('#filter-by-product');
    var clientSelect = formElem.find('#filter-by-client');

    productSelect.selectpicker({ liveSearch: true, liveSearchPlaceholder: "Filter by Product" })
      .ajaxSelectPicker({
        ajax: {
          url: "/business/search/products",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              localStorage.getItem("bp-x-csrf-token")
            );
          },
          data: function () {
            var params = {
              q: "{{{q}}}",
            };

            return params;
          },
        },
        clearOnEmpty: false,
        emptyRequest: true,
        cache: false,
        preserveSelected: false,
      });

    clientSelect.selectpicker({ liveSearch: true, liveSearchPlaceholder: "Filter by Client" })
      .ajaxSelectPicker({
        ajax: {
          url: "/business/search/client",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              localStorage.getItem("bp-x-csrf-token")
            );
          },
          data: function () {
            var params = {
              q: "{{{q}}}",
            };

            return params;
          },
        },
        clearOnEmpty: false,
        emptyRequest: true,
        cache: false,
        preserveSelected: false,
      });


  }

}