import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var controller = this;

    controller.setupFormListener();
    controller.triggerFormLoadAction();

    $('body').on('click', '#js-list-bookings-filter-upcoming', function(event) {
      $('#js-list-bookings-filter-form .js-filter').val('current');

      controller.triggerFormLoadAction();
      event.preventDefault();
    });

    $('body').on('click', '#js-list-bookings-filter-past', function(event) {
      $('#js-list-bookings-filter-form .js-filter').val('past');
      controller.triggerFormLoadAction();
      event.preventDefault();
    });

    $('body').on('click', '#js-booking-list-pagination a', function(event){
      var url = $(this).attr('href');
      controller.loadContent(url);

      event.preventDefault();
    });
  }

  triggerFormLoadAction() {
    $("#js-list-bookings-filter-form").find('select:first').trigger('change');
  }

  setupFormListener() {
    var formScope = $("#js-list-bookings-filter-form");
    var controller = this;

    formScope.find('select').on('change', function(event) {
      var url = formScope.attr("action") + '?' + formScope.serialize();

      controller.loadContent(url);

      event.preventDefault();
    });
  }

  loadContent(url) {
    $('.js-booking-list-loading').show();
    $('.js-booking-list-content').hide();

    $.ajax({
      url: url,
      type: 'get',
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          localStorage.getItem("bp-x-csrf-token")
        );
      },
      dataType: "script",
      success: (response) => {
        $('#js-index-list-view').html(response);
      },
      error: (response) => {
        window.notyf.error("Something went wrong when loading your request. Please refresh the page or try again.");
      },
    });
  }
}