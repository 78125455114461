import {Sortable, Plugins} from '@shopify/draggable';

$(document).on("turbolinks:load", function () {

  const courseOptions = {
    draggable: 'li.course-card',
    handle: '.js-draggable-course-handle',
    plugins: [Plugins.SwapAnimation],
    swapAnimation: {
      duration: 200,
      easingFunction: 'ease-in-out',
      horizontal: false
    }
  }

  const lessonOptions = {
    draggable: 'li.lesson-card',
    handle: '.js-draggable-lesson-handle',
    plugins: [Plugins.SwapAnimation],
    swapAnimation: {
      duration: 200,
      easingFunction: 'ease-in-out',
      horizontal: false
    }
  }

  const sortableOptions = {
    draggable: 'li.js-sortable',
    handle: '.js-sortable-handle',
    plugins: [Plugins.SwapAnimation],
    swapAnimation: {
      duration: 200,
      easingFunction: 'ease-in-out',
      horizontal: false
    }
  }

  const sortableCourse = new Sortable(document.querySelectorAll('ul.js-draggable-course'), courseOptions);
  const sortableLesson = new Sortable(document.querySelectorAll('ul.js-draggable-lesson'), lessonOptions);
  const sortable = new Sortable(document.querySelectorAll('ul.js-sortable'), sortableOptions);

  sortableCourse.on('sortable:stop', function(event){
    orderSorted(event, sortableCourse, 'ul.js-draggable-course', '.js-sort-order');
  });

  sortableLesson.on('sortable:stop', function(event){
    orderSorted(event, sortableLesson, 'ul.js-draggable-lesson', '.js-lesson-sort-order');
    associateCourseUnit(event);
  });

  sortableLesson.on('sortable:sort', function(event){
    if ($(event.data.dragEvent.data.overContainer).hasClass('js-no-drop')) {
      event.cancel();
    }
  });

  sortable.on('sortable:stop', function(event){
    orderSorted(event, sortable, 'ul.js-sortable', '.js-sort-order');
  });

  $('.js-add-course-unit').on('after-load', function(){
    document.querySelectorAll('ul.js-draggable-lesson').forEach(function(container){
      sortableLesson.addContainer(container);
    });

    document.querySelectorAll('ul.js-draggable-course').forEach(function(container){
      var courseUnits = sortableCourse.getDraggableElementsForContainer(container);
      setSortOrder(courseUnits, '.js-sort-order');
    });
  });

  $('.js-run-sortable').on('after-load', function(){
    document.querySelectorAll('ul.js-sortable').forEach(function(container){
      var sortables = sortable.getDraggableElementsForContainer(container);
      setSortOrder(sortables, '.js-sort-order');
    });
  });

  function orderSorted(event, func, conSelector, soSelector){
    var containers = $(conSelector).toArray();
    containers.forEach(function(container){
      var sortOrderElements = func.getDraggableElementsForContainer(container);
      var oIndex = event.oldIndex + 1;
      var nIndex = event.newIndex + 1;
      setSortOrder(sortOrderElements, soSelector);
      $(event.dragEvent.originalSource).find(soSelector).val(nIndex);
      if ($(event.dragEvent.originalSource).find(soSelector).parents('form.js-remote-form').is('*')) {
        $(event.dragEvent.originalSource).find(soSelector).parents('form.js-remote-form')[0].dispatchEvent(new Event("change"));
      }
    });
  }

  function setSortOrder(elements, selector) {
    var i = 0;
    elements.forEach(function(elem){
      i++;
      $(elem).find(selector).val(i);
      if ($(elem).find(selector).parents('form.js-remote-form').is('*')) {
        $(elem).find(selector).parents('form.js-remote-form')[0].dispatchEvent(new Event("change"));
      }
    });
  }

  function associateCourseUnit(event){
    var origLesson = $(event.dragEvent.originalSource);
    var container = $(event.newContainer).parents('.course-card');
    var time = Date.now();
    var allInputs = container.find('input, textarea, select').toArray();
    allInputs = allInputs.concat(origLesson.find('input, textarea, select').toArray());
    allInputs.forEach(function(input){
      var nameAttribute = input.getAttribute('name');
      input.setAttribute('name', nameAttribute.replace(/\[course_units_attributes\]\[.*?\]/, '[course_units_attributes]['+time+']'));
    });

    var unit_id = $(event.newContainer).parents('.course-card').find('.js-course-unit-id').val();
    origLesson.find('.js-lesson-cuid').val(unit_id);

    container = $(event.newContainer);
    var lessons = container.find('li.lesson-card').toArray();
    lessons = lessons.concat(origLesson.toArray());
    var i = 0;
    lessons.forEach(function(lesson){
      i++;
      var inputs = $(lesson).find('input, textarea, select').toArray();
      inputs.forEach(function(input){
        var nameAttribute = input.getAttribute('name');
        input.setAttribute('name', nameAttribute.replace(/\[lessons_attributes\]\[.*?\]/, '[lessons_attributes]['+i+']'));
      })
    });

    origLesson.find('.js-delete-field').removeClass('d-none');
  }
});
