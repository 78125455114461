import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    this.updateTaxRatePreview();
  }

  updateTaxRatePreview(){
    if($('.js-tax-rate-label').val() == '' || $('.js-tax-rate-percent').val() == ''){

      if($('.js-tax-rate-percent').val() == '') {
        var taxRatePercent = 5.0;
      } else {
        var taxRatePercent = parseFloat($('.js-tax-rate-percent').val());
      }

      if($('.js-tax-rate-label').val() == ''){
        var taxRateLabel = 'TAX'
      } else {
        var taxRateLabel = $('.js-tax-rate-label').val();
      }

      var subtotal = 100.00;
      var taxAmount = 100.00 * (taxRatePercent/100);

    } else {
      var subtotal = 100.00;
      var taxRateLabel = $('.js-tax-rate-label').val();
      var taxRatePercent = parseFloat($('.js-tax-rate-percent').val());
      var taxAmount = 100.00 * (taxRatePercent/100);
    }

    if($('.js-tax-inclusive-flag').is(":checked")) {
      var total = 100;
    } else {
      var total = taxAmount + subtotal;
    }

    $('.js-tax-rate-label-preview').html(taxRateLabel);
    $('.js-tax-rate-percent-preview').html(taxRatePercent);
    $('.js-tax-amount-preview').html(taxAmount.toFixed(2));
    $('.js-tax-total-preview').html(total.toFixed(2));
  }
}