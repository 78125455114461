import { Controller } from "stimulus";
var onmount = require("onmount");

export default class extends Controller {

  loadRemoteContent() {
    var controller = this;
  	var replaceContentContainer = $(controller.element.dataset.target).find('.js-remote-content-load-container');
    var remoteUrl = replaceContentContainer.data('loadRemoteContentUrlValue');

    fetch(remoteUrl)
      .then((response) => response.text())
      .then(function (html) {
        replaceContentContainer.html(html);

        $('[data-toggle="tooltip"]').tooltip({ sanitize: false });

        onmount();
      });
  }
}
