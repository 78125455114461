$(document).on("turbolinks:load", function () {
  if ($('#pos-connection-status').is('*')) {
    // Initialize Stripe Terminal
    var terminal = StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });

    var loaderWrapper = $('#pos-connection-status');
    var simulated = $('#pos-connection-status').data('simulated');
    // Search for local terminals
    connectToReader(terminal, simulated, loaderWrapper);
  }

  if($('#js-payment-client-select').is('*')) {

    var params = new URLSearchParams(window.location.search);
    var clientId = params.get('client');
    var invoiceId = params.get('invoice');

    $('#js-payment-client-select')
      .selectpicker()
      .ajaxSelectPicker({
        ajax: {
          url: "/business/search/client?new_object=true",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              localStorage.getItem("bp-x-csrf-token")
            );
          },
          data: function () {
            var params = {
              q: "{{{q}}}",
            };
            return params;
          },
        },
        clearOnEmpty: false,
        emptyRequest: true,
        cache: false,
        preserveSelected: false,
      });

    updateClientInformation(clientId, invoiceId);
    bindChange();

    $('#js-payment-client-select').on('change', function(){
      updateClientInformation();
    });

    $('#js-add-line-item').on('after-load', function(){
      legacyUpdateTitlesAndID();
      bindChange();
      $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
      });
    });
  }

  $('#js-send-to-pos-btn').on('click', function(e){
    e.preventDefault();
    var submitButton = $(this);
    submitButton.prop('disabled', true);

    var form = $(this).closest("form");
    var url = form.attr("action");

    if(validateForm(e, form[0])) {
      var posStatus = $('#pos-status-text');
      $('#loading-pos-modal').modal('show');

      $.ajax({
        type: "POST",
        url: url,
        data: form.serialize(),
        dataType: "script",
        success: (response) => {
          var json = JSON.parse(response);

          if (json.error !== null && json.error == true) {
            window.notyf.error(json.message);
            setTimeout(function () {
              window.location.href = "/business/payments/new";
            }, 1500);
          }

          if (simulated) {
            var cardType = $("#pos-connection-status").data("card-type");
            terminal.setSimulatorConfiguration({ testPaymentMethod: cardType });
          }

          terminal
            .collectPaymentMethod(json.payment_intent)
            .then(function (result) {
              if (result.error) {
                console.log("Error collecting Payment Method", result.error);

                setTimeout(function () {
                  $("#loading-pos-modal").modal("hide");
                  submitButton.prop("disabled", false);
                  window.notyf.error({
                    message: result.error.message,
                    duration: 4500,
                  });
                }, 1000);
              } else {
                console.log("Successfully collected Payment Method");
                posStatus.html("Card captured, confirming payment...");
                terminal
                  .processPayment(result.paymentIntent)
                  .then(function (result) {
                    if (result.error) {
                      console.log(
                        "Error confirming Payment Intent",
                        result.error
                      );

                      setTimeout(function () {
                        $("#loading-pos-modal").modal("hide");
                        submitButton.prop("disabled", false);
                        window.notyf.error({
                          message: result.error.message,
                          duration: 4500,
                        });
                      }, 1000);
                    } else if (result.paymentIntent) {
                      $("#js-cancel-pos-btn").prop("disabled", true);
                      $(".modal-footer").slideUp("fast");

                      posStatus.html("Payment approved! Finalizing invoice...");
                      console.log(
                        "Sending successsful Payment Intent for capture"
                      );

                      $(".loader").fadeOut("fast", function () {
                        $(".confirm-check").fadeIn("fast");
                      });

                      $.ajax({
                        type: "POST",
                        url: "/business/payments/capture_payment_intent",
                        beforeSend: function (xhr) {
                          xhr.setRequestHeader(
                            "X-CSRF-Token",
                            localStorage.getItem("bp-x-csrf-token")
                          );
                        },
                        data: { payment_intent_id: result.paymentIntent.id },
                        dataType: "script",
                        success: (response) => {
                          var json = JSON.parse(response);
                          posStatus.html("Invoice finalized. Redirecting...");

                          setTimeout(function () {
                            window.location.href =
                              "/business/clients/" +
                              json.client_id +
                              "/invoices/" +
                              json.invoice_id;
                          }, 5000);
                        },
                        error: (response) => {
                          posStatus.html(
                            "We had an issue finalizing the invoice. We will attempt to resolve this in the background."
                          );

                          setTimeout(function () {
                            window.location.href = "/business/payments/new";
                          }, 2000);
                        },
                      });
                    }
                  });
              }
            });
        },
        error: (response) => {
          submitButton.prop("disabled", false);
          window.notyf.error(
            "Sorry, something went wrong. This payment has not been processed."
          );
        },
      });
    } else {
      submitButton.prop("disabled", false);
    }
  });

  $("#js-cancel-pos-btn").on("click", function (e) {
    e.preventDefault();
    terminal.cancelCollectPaymentMethod();
    $("#loading-pos-modal").modal("hide");
    $("#js-send-to-pos-btn").prop("disabled", false);
    window.notyf.error("Transaction canceled");
  });

  $(".js-refund-on-pos-btn").on("click", function (e) {
    e.preventDefault();
    var submitButton = $(this);
    submitButton.prop("disabled", true);

    var form = $(this).closest("form");
    var url = form.attr("action");

    var stripeCharge = form.find(".js-stripe-charge-id").val();
    var refundAmount = parseInt(form.find(".js-refund-amount").val() * 100);

    if (validateForm(e, form[0])) {
      var posStatus = $("#pos-status-text");
      $("#loading-pos-modal").modal("show");

      if (simulated) {
        var cardType = $("#pos-connection-status").data("card-type");
        terminal.setSimulatorConfiguration({ testPaymentMethod: cardType });
      }

      terminal
        .collectRefundPaymentMethod(stripeCharge, refundAmount, "cad", {reverse_transfer: true})
        .then(function (collectRefundPaymentMethodResult) {
          if (collectRefundPaymentMethodResult.error) {
            console.log(
              "Error collecting Payment Method",
              collectRefundPaymentMethodResult.error
            );

            setTimeout(function () {
              $("#loading-pos-modal").modal("hide");
              submitButton.prop("disabled", false);
              window.notyf.error({
                message: collectRefundPaymentMethodResult.error.message,
                duration: 4500,
              });
            }, 1000);
          } else {
            posStatus.html("Card captured, processing refund...");
            terminal.processRefund().then(function (processRefundResult) {
              if (processRefundResult.error) {
                $("#loading-pos-modal").modal("hide");
                submitButton.prop("disabled", false);
                window.notyf.error(
                  "Error processing refund. Funds not returned."
                );
              } else {
                $("#js-cancel-pos-btn").prop("disabled", true);
                $(".modal-footer").slideUp("fast");

                posStatus.html("Funds returned, processing invoice...");
                console.log("Sending payment details to back-end");

                $(".loader").fadeOut("fast", function () {
                  $(".confirm-check").fadeIn("fast");
                });

                $.ajax({
                  type: "PATCH",
                  url: url,
                  beforeSend: function (xhr) {
                    xhr.setRequestHeader(
                      "X-CSRF-Token",
                      localStorage.getItem("bp-x-csrf-token")
                    );
                  },
                  data: form.serialize(),
                  dataType: "script",
                  success: (response) => {
                    var json = JSON.parse(response);
                    posStatus.html("Invoice finalized. Redirecting...");

                    setTimeout(function () {
                      window.location.href =
                        "/business/clients/" +
                        json.client_id +
                        "/invoices/" +
                        json.invoice_id;
                    }, 2000);
                  },
                  error: (response) => {
                    posStatus.html(
                      "We had an issue marking the payment refunded - please contact us for more information."
                    );
                  },
                });
              }
            });
          }
        });
    } else {
      submitButton.prop("disabled", false);
    }
  });

  if ($(".js-activate-pos").is("*")) {
    $(".js-activate-pos").on("submit", function (e) {
      e.preventDefault();
      var form = $(this);
      var url = form.attr("action");
      var data = form.serializeArray();

      console.log(data);

      form.find("input, button").prop("disabled", true);

      $.ajax({
        type: "POST",
        url: url,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: data,
        dataType: "script",
        success: (response) => {
          form.fadeOut("fast", function () {
            form.append("Activated!");
            form.remove();
            window.notyf.success(
              "This device has been successfully activated!"
            );
          });
        },
        error: (response) => {
          window.notyf.error(
            "We were unable to activate this device. Please ensure the pairing code is correct and it is connected to the internet."
          );
          form.find("input, button").prop("disabled", false);
          form.find("button").html("Activate");
        },
      });
    });
  }
});

function fetchConnectionToken() {
  return fetch("/business/payments/fetch_terminal_connection_token", {
    method: "POST",
    headers: { "X-CSRF-Token": localStorage.getItem("bp-x-csrf-token") },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data.secret;
    });
}

function unexpectedDisconnect() {
  var terminal = StripeTerminal.create({
    onFetchConnectionToken: fetchConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });

  var loaderWrapper = $('#pos-connection-status');
  var simulated = $('#pos-connection-status').data('simulated');

  loaderWrapper.find('.status').slideUp('fast', function(){
    loaderWrapper.find('.error').slideDown('fast');
  });

  window.notyf.error('Reader disconnected, attempting to reconnect.');

  $('#loading-pos-modal').modal('hide');
  $('#js-send-to-pos-btn, .js-refund-on-pos-btn').prop('disabled', true);

  connectToReader(terminal, simulated, loaderWrapper);
}

function connectToReader(terminal, simulated, loaderWrapper) {
  var location = $('#stripe-location').val();

  if (simulated) {
    var config = {simulated: simulated, location: location };
  } else {
    var config = { location: location };
  }

  terminal.discoverReaders(config).then(function(discoverResult) {
    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error);
      loaderWrapper.find('.status').slideUp('fast', function(){
        loaderWrapper.find('.error').slideDown('fast');
      });

    } else if (discoverResult.discoveredReaders.length === 0) {
      console.log(discoverResult)
      console.log('No available readers.');
      loaderWrapper.find('.status').slideUp('fast', function(){
        loaderWrapper.find('.not-found').slideDown('fast');
      });

      setTimeout(function(){
        connectToReader(terminal, simulated, loaderWrapper)
      }, 1000);

    } else {
      // Just select the first reader here.
      var selectedReader = discoverResult.discoveredReaders[0];

      terminal.connectReader(selectedReader).then(function(connectResult) {
        if (connectResult.error) {
          console.log('Failed to connect: ', connectResult.error);
          loaderWrapper.find('.status').slideUp('fast', function(){
            loaderWrapper.find('.error').slideDown('fast');
          });

          setTimeout(function(){
            connectToReader(terminal, simulated, loaderWrapper)
          }, 1000);

        } else {
          console.log('Connected to reader: ', connectResult.reader.label);
          loaderWrapper.find('.status').slideUp('fast', function(){
            loaderWrapper.find('.connected').slideDown('fast');
          });

          $('#js-send-to-pos-btn, .js-refund-on-pos-btn').prop('disabled', false);
        }
      });
    }
  });
}

function updateClientInformation(preClientID, invoiceID) {
  if (preClientID) {
    var clientID = preClientID;
    $('#js-payment-client-select option[value='+clientID+']').prop('selected', true);
  } else {
    var clientID = $('#js-payment-client-select').find(':selected').val();
  }

  $('#js-invoice-menu').slideUp('fast', function(){
    $('#js-invoice-menu').html('');
  });

  if (clientID && clientID != 'new_client') {
    $('#js-new-client-fields').slideUp('fast', function(){
      $('#js-new-client-fields').find('input, select, button').prop('disabled', true);
      $('#js-new-client-fields').find('.dropdown-toggle, .droptdown').addClass('disabled');
    });

    $.ajax({
      url: '/business/payments/find_and_render_client_information',
      type: "GET",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          localStorage.getItem("bp-x-csrf-token")
        );
      },
      data: { client_id: clientID },
      dataType: 'script',
      success: function(){
        setTimeout(function(){
          $('#js-invoice-select').on('change', function(){
            updateInvoiceData();
          });

          updateInvoiceData(invoiceID);
        }, 500);
      }
    });

  } else if (clientID && clientID == 'new_client') {
    $('#js-existing-client').slideUp('fast', function(){
      $('#js-new-client-fields').find('input, select, button').prop('disabled', false);
      $('#js-new-client-fields').find('.dropdown-toggle, .droptdown').removeClass('disabled');
      $('#js-new-client-fields').slideDown('fast');
    });
    updateInvoiceData();
  }

}

function updateInvoiceData(preInvoiceID) {
  if (preInvoiceID) {
    var invoiceID = preInvoiceID;
    $('#js-invoice-select option[value='+invoiceID+']').prop('selected', true).trigger('change');
  } else {
    var invoiceID = $('#js-invoice-select').find(':selected').val();
  }

  $('#js-invoice-line-items, #js-invoice-totals').slideUp('fast', function(){

    $('#js-loader').fadeIn('fast');

    if (invoiceID && invoiceID != 'new') {
      $('#js-new-line-items').hide();
      $('#js-new-invoice-totals').hide();
      $('#js-new-line-items').find('input, select').prop('disabled', true);
      $('#js-new-invoice-totals').find('input').prop('disabled', true);

      $.ajax({
        url: '/business/payments/find_and_render_invoice',
        type: "GET",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: { invoice_id: invoiceID },
        dataType: 'script',
        success: function(){
          $('#js-loader').fadeOut('fast', function(){
            $('#js-invoice-line-items, #js-invoice-totals').slideDown('fast');

            var dueNow = $('#js-due-now').data('due');
            var totalToCharge = $('#js-total-to-charge');
            
            totalToCharge.val((dueNow).toFixed(2));
            totalToCharge.attr('max', (dueNow).toFixed(2));
          });
        }
      });
    } else {
      $('#js-existing-invoice-line-items').hide();
      $('#js-existing-invoice-totals').hide();
      $('#js-new-line-items').find('input, select').prop('disabled', false);
      $('#js-new-invoice-totals').find('input').prop('disabled', false);
      $('#js-new-line-items').show();
      $('#js-new-invoice-totals').show();

      $('#js-loader').fadeOut('fast', function(){
        $('#js-invoice-line-items, #js-invoice-totals').slideDown('fast');
      });

      refreshTotals();
    }
  });
}

function refreshTotals() {
  var lineItems = $('.js-line-item-row:visible');
  var lineItemsSubtotal = 0.00;
  var subTotalField = $('#pos-payment-form #js-subtotal span');
  var totalField = $('#pos-payment-form #js-invoice-total span');
  var processingFeeField = $('#pos-payment-form #js-processing-fee');
  var discountField = $('#pos-payment-form #js-discount-field');
  var discount = parseFloat(discountField.val());
  var taxableSubtotal = 0.0;
  var inclusiveTaxTotal = 0.0;
  var taxTotal = 0.0;  
  var taxRate = 0.0;

  var discountableSubtotal = 0.0;
  var currencySymbol = $('#pos-payment-form').data('currency-symbol');
  var totalToCharge = $('#js-total-to-charge');

  $.each(lineItems, function(index, lineItemRow){
    var lineItem = $(this);
    var lineRate = parseFloat(lineItem.data('tax-rate'));
    var discountable = lineItem.data('discountable');

    var qty = parseFloat(lineItem.find('input.qty').val());
    var price = parseFloat(lineItem.find('input.unit-price').val());

    if (!isNaN(qty) && !isNaN(price)) {
      var lineTotal = qty * price;

      lineItemsSubtotal += lineTotal;

      if(discountable) {
        discountableSubtotal += lineTotal;
      }

      if (lineRate > 0) {
        taxableSubtotal += lineTotal;
      }
    }
  });

  var discount_by_percent = $('#pos-payment-form .discount-by-percent').is(':checked');

  if (discount > 0) {

    if (discount_by_percent) {
      var discount_value = discountableSubtotal * (discount/100);
    } else {
      var discount_value = discount;
    }
  } else {
    discount_value = 0;
  }

  subTotalField.html(currencySymbol + lineItemsSubtotal.toFixed(2));

  if ($('#pos-payment-form .js-tax').is('*')) {
    var taxTotalFields = $('#pos-payment-form .js-tax .row');

    // calculate and display tax
    $.each(taxTotalFields, function(index, taxTotalField){
      let tax_subtotal = 0.0;
      let tax_description = $(taxTotalField).data('tax-rate-name');

      $.each(lineItems, function(index, lineItemRow){
        let lineItem = $(lineItemRow);
        let taxRates = lineItem.data('tax-rates-as-json')
        let matchingTaxRate = taxRates.find(tax => tax.description === tax_description);
        let qty = parseFloat(lineItem.find('input.qty').val());
        let price = parseFloat(lineItem.find('input.unit-price').val());
        let discountField = $('#pos-payment-form .discount-field');
        let discount = parseFloat(discountField.val());
        let discountable = lineItem.data('discountable');

        if(matchingTaxRate !== undefined && !isNaN(qty) && !isNaN(price)) {
          let lineTotal = qty * price;
          let lessDiscount = 0.0;

          if(discountable && discount > 0.0) {
            let discountablePercentage = (parseFloat(lineTotal) / parseFloat(discountableSubtotal));
            lessDiscount = parseFloat(discountablePercentage * discount);
          }

          tax_subtotal += (lineTotal - lessDiscount) * (parseFloat(matchingTaxRate.rate) / 100.0);
        }
      });
 
      $(taxTotalField).find('span').html(currencySymbol + tax_subtotal.toFixed(2));
      taxTotal += tax_subtotal;

      if($(taxTotalField).data('tax-inclusive') == true) {
        inclusiveTaxTotal += tax_subtotal.toFixed(2);
      }
    });

    setDiscountMax(discountField, discountableSubtotal, discount_by_percent);

    total = (taxTotal - inclusiveTaxTotal) + (lineItemsSubtotal - discount_value);

  } else {
    setDiscountMax(discountField, lineItemsSubtotal, discount_by_percent);
    total = (lineItemsSubtotal - discount_value);
  }

  if(processingFeeField.is('*')) {
    $.getJSON('/business/invoices/get_processing_fee.json?total=' + total.toFixed(2), function(data) {
      processingFeeField.find('span').html(currencySymbol + data['processing_fee']);
      totalField.html(currencySymbol + (total + data['processing_fee']).toFixed(2));

      totalToCharge.val((total + data['processing_fee']).toFixed(2));
      totalToCharge.attr('max', (total + data['processing_fee']).toFixed(2));
    });
  } else {
    totalField.html(currencySymbol + total.toFixed(2));
    totalToCharge.val((total).toFixed(2));
    totalToCharge.attr('max', (total).toFixed(2));
  }
}

function setDiscountMax(discountField, subtotal, discount_by_percent) {
  if (discount_by_percent) {
    discountField.prop('max', '100');
  } else {
    discountField.prop('max', subtotal);
  }
}


function legacyUpdateTitlesAndID() {
  var select = $(this).find(':selected');
  var type = select.data('type');

  var line_item_row = $(this).closest('.js-line-item-row')

  var addon_id = line_item_row.find('.js-addon-id');
  var service_id = line_item_row.find('.js-service-id');
  var series_id = line_item_row.find('.js-series-id');
  var package_id = line_item_row.find('.js-package-id');

  if (select.data('title') != 'undefined') {
    var title_text = select.data('description');
    var price = select.data('price');

    line_item_row.find('.title-field').val(title_text);
    line_item_row.find('.unit-price').val(price);

    if (select.val() == 'custom') {
      line_item_row.find('.qty').attr('data-original-title', '');
    } else if (select.val() == 'gc') {
      line_item_row.find('.qty').attr('data-original-title', 'Used to calculate total value.');
    } else {
      var tooltip = ''
      switch (type) {
        case 'addon':
          tooltip = 'The number of credits created for '+ select.data('addon-name') +' addon(s).'
          break;

        case 'service':
          tooltip = 'The number of credits created for '+ select.data('service-name') +' bookings.'
          break;

        case 'series':
          tooltip = 'A quantity of 1 will provide enough credits for all bookings within a '+ select.data('series-name') +' Series.'
          break;

        case 'package':
          tooltip = 'A quantity of 1 will provide all credits within the '+ select.data('package-name') +' package.'
          break;

      }

      line_item_row.find('.qty').attr('data-original-title', tooltip);
    }
  }

  $(addon_id).val('');
  $(service_id).val('');
  $(series_id).val('');
  $(package_id).val('');

  if (type == 'addon') {
    addon_id.val(select.val());
  } else if (type == 'service') {
    service_id.val(select.val());
  } else if (type == 'series') {
    series_id.val(select.data('series-id'));
  } else if (type == 'package') {
    package_id.val(select.val());
  }

  refreshTotals();
}

function bindChange() {
  var currencySymbol = $('#pos-payment-form').data('currency-symbol');

  $('.js-remove-line-item').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    $(this).closest(".js-line-item-row").remove();

    refreshTotals();
  });

  $('.js-edit-line-item').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var lineItemEditClass = 'edit-line-item-row-' + Date.now();
    window.lineItemEditClass = '.' + lineItemEditClass;

    $(this).closest('.line-item-row').addClass(lineItemEditClass);

    $('#line-builder-modal').modal();
  });

  $('#js-invoice-add-line-item').on('click', function(e){
    window.lineItemEditClass = '';
  });

  $('.invoice-line-item-select').on('change', legacyUpdateTitlesAndID);
  $('.invoice-line-item-select, .line-item-row, .js-line-item-row input.unit-price, .js-line-item-row input.qty, #js-discount-field, .discount-by-percent, .discount-by-value').on('click change keyup', function(){

    var lineItemScope = $(this).closest(".js-line-item-row");
    var lineItemTotalField = lineItemScope.find('.line-item-total-field');

    var qty = parseFloat(lineItemScope.find('input.qty').val());
    var price = parseFloat(lineItemScope.find('input.unit-price').val());
    var lineItemTotal = qty * price;

    if (isNaN(lineItemTotal)) {
      lineItemTotalField.html(currencySymbol+"0.00");
    } else {
      lineItemTotalField.html(currencySymbol + lineItemTotal.toFixed(2));
    }

    refreshTotals();
  });
}
