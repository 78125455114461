import SignaturePad from "signature_pad";

// This js module allows the signature_pad functionality in the intake
// templates show template to activate and allow the signature to be written
// with the mouse. Most of this code from is cribbed from the customers version
// in app/packs/javascripts/pages/customers/intake_forms.js - not all of it
// though.
$(document).on("turbolinks:load", function () {
  var canvas = document.querySelectorAll("canvas");
  var sigPads = initializeCanvas();

  function initializeCanvas() {
    var sigPads = {};
    canvas.forEach(function (sig) {
      sigPads[$(sig).closest("form").data("form-key")] = new SignaturePad(sig);

      $(sig)
        .siblings(".clear")
        .click(function (event) {
          event.preventDefault();
          sigPads[$(sig).closest("form").data("form-key")].clear();
        });
    });

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    $(".nav-tabs")
      .find("a")
      .on("shown.bs.tab", function () {
        resizeCanvas();
      });

    return sigPads;
  }

  function resizeCanvas() {
    canvas.forEach(function (sig) {
      const width = sig.clientWidth;
      const height = sig.clientHeight;

      if (sig.width !== width || sig.height !== height) {
        sig.width = width;
        sig.height = height;
        return true;
      }
    });
  }
});
