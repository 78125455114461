$(document).on("turbolinks:load", function () {
  if ($('#addons_for_today_modal').is('*')) {

    $('body').on('click', '#addons-for-today-modal-btn', function () {
      if ($('#addons_for_today_modal').hasClass('is-loading')){
        loadAddonsModalContents($(this));
      }
    });
  }
});


const loadAddonsModalContents = (element) => {
  var loadRemoteUrl = element.data('loadUrl');

  fetch(loadRemoteUrl)
    .then(response => response.text())
    .then(function(html){
      $('#addons_for_today_modal .modal-body').html(html);
    });
}