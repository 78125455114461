$(document).on("turbolinks:load", function () {

  if($('.js-country-and-prov-select').is('*')){
    var country = $('.js-country-and-prov-select').val();
    checkAndLoadSelect(country);
    setPostalValidation(country);

    $('.js-country-and-prov-select').change(function(){
      var country = $(this).val();
      var postalCode = $('.js-validate-postal-code').val();

      checkAndLoadSelect(country);
      setPostalValidation(country);
    })
  }

});

function checkAndLoadSelect(country) {
  if (country == 'canada') {
    $('.js-prov-select').prop('disabled', false).removeClass('d-none').children('button').removeClass('disabled');
    $('.js-usa-state-select').prop('disabled', true).addClass('d-none').children('button').addClass('disabled');
    $('.js-prov-entry').prop('disabled', true).addClass('d-none');

  } else if (country == 'usa') {
    $('.js-prov-select').prop('disabled', true).addClass('d-none').children('button').addClass('disabled');
    $('.js-usa-state-select').prop('disabled', false).removeClass('d-none').children('button').removeClass('disabled');
    $('.js-prov-entry').prop('disabled', true).addClass('d-none');

  } else {
    $('.js-prov-select').prop('disabled', true).addClass('d-none').children('button').addClass('disabled');
    $('.js-usa-state-select').prop('disabled', true).addClass('d-none').children('button').addClass('disabled');
    $('.js-prov-entry').prop('disabled', false).removeClass('d-none');
  }
}

function setPostalValidation(country) {
  if (country == 'canada') {
    var regex = '^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ][\\- ]?\\d[ABCDEFGHIJKLMNOPQRSTUVWXYZ]\\d$';
    var message = "Enter a valid postal code. Format: A1A 1A1";
  } else if (country == 'usa') {
    var regex = '(^\\d{5}$)|(^\\d{5}-\\d{4}$)';
    var message = "Enter a valid postal code. Format: 10001"
  } else {
    var regex = null;
    var message = "Enter a valid postal code.";
  }

  if (regex) {
    $('.js-validate-postal-code').prop('pattern', regex);
  } else {
    $('.js-validate-postal-code').removeAttr('pattern');
  }

  $('.js-validate-postal-code')[0].title = message;
}
