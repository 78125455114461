$(document).on("turbolinks:load", function () {
  if ($('.js-resource-type-select').is('*')) {
    $('.js-resource-type-select').on('change', function(){
      var type = $(this).find(":selected").val();
      var concurrentServicesWrapper = $('.js-concurrent-services')

      if (type == 'room') {
        concurrentServicesWrapper.find('input').prop('disabled', false);
        concurrentServicesWrapper.slideDown('fast');
      } else {
        concurrentServicesWrapper.slideUp('fast');
        concurrentServicesWrapper.find('input').prop('disabled', true);
      }

    });

  }
});
