import { Controller } from "stimulus";
import { Decimal } from "decimal.js";
var moment = require("moment-timezone");

export default class extends Controller {
  static targets = [
    "startAtDate",
    "startAtTime",
    "endAtDate",
    "endAtTime",
    "output",
  ];

  connect() {
    this.element["stimulus_controller"] = this;
    this.inputChanged(null);
  }

  // event: Event
  inputChanged(event) {
    let start_at_date = moment(this.startAtDateTarget.value, ["YYYY-MM-DD"]);
    let end_at_date = moment(this.endAtDateTarget.value, ["YYYY-MM-DD"]);

    // This code adjusts the end_at date to be at least the start_at date
    if (start_at_date.diff(end_at_date, "days") >= 1) {
      this.endAtDateTarget.value = this.startAtDateTarget.value;
    }

    // They have provided at least 3 characters in the time field
    if (
      this.endAtTimeTarget.value.length >= 3 &&
      this.startAtTimeTarget.value.length >= 3
    ) {
      let start_at = `${this.startAtDateTarget.value} ${this.startAtTimeTarget.value}`;
      let start_at_date_time = moment(start_at, [
        "YYYY-MM-DD h:mm A",
        "YYYY-MM-DD h A",
      ]);

      let end_at = `${this.endAtDateTarget.value} ${this.endAtTimeTarget.value}`;
      let end_at_date_time = moment(end_at, [
        "YYYY-MM-DD h:mm A",
        "YYYY-MM-DD h A",
      ]);

      if (start_at_date_time.isValid() && end_at_date_time.isValid()) {
        let hours = end_at_date_time
          .diff(start_at_date_time, "hours", true)
          .toFixed(2);
        this.outputTarget.innerHTML = hours;

        if (hours < 0.0) {
          this.endAtTimeTarget.setCustomValidity(
            "Clock-out must happen after clock-in"
          );
        } else {
          this.endAtTimeTarget.setCustomValidity("");
        }
      } else {
        this.endAtTimeTarget.setCustomValidity("");
      }
    } else {
      this.endAtTimeTarget.setCustomValidity("");
      this.outputTarget.innerHTML = "-";
    }

    this.endAtTimeTarget.reportValidity();
  }
}
