var { Timer } = require("easytimer.js");

onmount(".js-timer", function () {
  window.setupJSTimer(this);
});

window.setupJSTimer = function(elem) {
  let timer = new Timer({
    startValues: {
      seconds: elem.dataset.startAtSeconds,
      minutes: elem.dataset.startAtMinutes,
      hours: elem.dataset.startAtHours,
    },
  });

  let x = {
    seconds: elem.dataset.startAtSeconds,
    minutes: elem.dataset.startAtMinutes,
    hours: elem.dataset.startAtHours,
  };

  let the_timer_html = elem;
  $(the_timer_html).html(timer.getTimeValues().toString());
  if (elem.dataset.startTimer == "true") {
    timer.start();
  }

  timer.addEventListener("secondsUpdated", function (e) {
    $(the_timer_html).html(timer.getTimeValues().toString());
  });
}
