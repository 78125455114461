$(document).on("turbolinks:load", function () {
  onmount(".needs-validation", function () {
    var forms = document.getElementsByClassName('needs-validation');
    forms.forEach( (form) => {
      form.addEventListener('submit', function (event) {
        validateForm(event, form);
      }, false);
    });
  });
});

window.validateForm = function(event, form) {
  var valid = form.checkValidity();
  if (valid === false) {
    $(form).find('.please-enter-required-fields').show();

    event.preventDefault();
    event.stopPropagation();

    // add valid/invalid class to tabs after child inputs are validated
    if ( $(form).find('.tab').length !== 0 ) {
      form.getElementsByClassName('tab-pane').forEach( (tab) => {
        var tab_id = $(tab).attr('id');
        var tab_nav = $('a[href*="#'+tab_id+'"]').parents('.nav-item')
        if ( $(tab).find(':invalid').length !== 0 ) {
          $(tab_nav).removeClass('is-valid');
          $(tab_nav).addClass('is-invalid');
        } else {
          $(tab_nav).removeClass('is-invalid');
          $(tab_nav).addClass('is-valid');
        }
      });
    }

    //adjust UI elements to indicate validation

    if ( $(form).find('.card').length !== 0 ) {
      form.getElementsByClassName('card').forEach( (card) => {

        var card_header = $(card).find('.card-header');

        $(card).addClass('border');

        if ( $(card).find(':invalid').length !== 0 ) {
          $(card).removeClass('border-success');
          $(card_header).removeClass('is-valid');
          $(card_header).addClass('is-invalid');
          $(card).addClass('border-danger');
        } else {
          $(card).removeClass('border-danger');
          $(card_header).removeClass('is-invalid');
          $(card_header).addClass('is-valid');
          $(card).addClass('border-success');
        }
      });
    }

  }
  form.classList.add('was-validated');

  return valid
}
