$(document).on("turbolinks:load", function () {
  // Replace browser confirms with Bootstrap confirm
  Rails.confirm = function (message, element) {
    let $element = $(element);
    let $dialog = $("#confirmation-modal");
    let $content = $dialog.find("#modal-content");

    let $ok = $dialog.find("#ok-button");
    let $cancel = $dialog.find("#cancel-button");

    if ($element.data("oktext") === undefined) {
      $ok.find(".default-text").show();
      $ok.find(".custom-text").hide();
    } else {
      $ok.find(".default-text").hide();
      $ok.find(".custom-text").html($element.data("oktext")).show();
    }

    if ($element.data("canceltext") === undefined) {
      $cancel.find(".default-text").show();
      $cancel.find(".custom-text").hide();
    } else {
      $cancel.find(".default-text").hide();
      $cancel.find(".custom-text").html($element.data("canceltext")).show();
    }

    $content.html(message);

    $ok.click(function (event) {
      event.preventDefault();
      $dialog.modal("hide");
      let old = Rails.confirm;
      Rails.confirm = function () {
        return true;
      };

      $element.get(0).click();
      Rails.confirm = old;
    });

    $cancel.click(function (event) {
      event.preventDefault();
      event.stopPropagation();
      $dialog.modal("hide");

      // Bug Fix: If the confirmation modal is opened within another modal and cancel is clicked; The modal becomes unscrollable.
      let $openModal = $('.modal:visible').not("#confirmation-modal");

      if($openModal.is('*') && $openModal.length == 1){
        setTimeout(function() { $($openModal).modal('hide'); }, 50);
        setTimeout(function() { $($openModal).modal('show'); }, 400);
      }

      let old = Rails.confirm;
      Rails.confirm = function () {
        return true;
      };
      Rails.confirm = old;

    });

    $dialog.modal("show");
    return false;
  };
});

$(document).on("turbolinks:before-cache", function () {
  // Manually tear down bootstrap modals before caching. If turbolinks
  // caches the modal then tries to restore it, it breaks bootstrap's JS.
  // We can't just use bootstrap's `modal('close')` method because it is async.
  // Turbolinks will cache the page before it finishes running.
  // Source: https://www.honeybadger.io/blog/turbolinks/
  //
  // This fixes an issue in the BusyPaws code base where navigating back to pages
  // with the modal previously open (i.e. 'edit this + future' booking edit
  // modal) would result in the page being unclickable because an invisible modal
  // background has taken over the user's screen.
  if (document.body.classList.contains("modal-open")) {
    $(".modal").hide().removeAttr("aria-modal").attr("aria-hidden", "true");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  }
});
