import { Controller } from "stimulus";


export default class extends Controller {

  connect() {
    this.setupWeeklyDatepicker();
  }

  setupWeeklyDatepicker() {
    var controller = this;
    var baseUrl = $("#" + this.element.id + " .btn-date-range").data('url');

    $("#" + this.element.id + " .btn-date-range").flatpickr({
      onChange: function(selectedDates, dateStr, instance) {
        var url = baseUrl + "&date=" + dateStr;
        controller.updateSchedule(url);

      }
    });
  }

  previousWeek(event){
    event.preventDefault();
    var url = $("#" + this.element.id + " .js-previous-week").prop('href');
    this.updateSchedule(url);
  }

  nextWeek(event){
    event.preventDefault();
    var url = $("#" + this.element.id + " .js-next-week").prop('href');
    this.updateSchedule(url);
  }

  today(event){
    event.preventDefault();
    var url = $("#" + this.element.id + " .js-today").prop('href');
    this.updateSchedule(url);
  }

  updateSchedule(url){
    var contentTarget = "#" + this.element.id;
    var controller = this;

    fetch(url)
      .then(response => response.text())
      .then(function(html){
        $(contentTarget).html(html);
        controller.setupWeeklyDatepicker();
        window.setupCopyToKeyboardBindings();
      });
  }

}