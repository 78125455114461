import { Controller } from "stimulus";

export default class extends Controller {

  static values = {
    replaceLoaderTarget: String,
    replaceTarget: String,
  }

  connect() {
    var controller = this;
    var replaceLoaderTargetValue = this.replaceLoaderTargetValue;
    var replaceTargetValue = this.replaceTargetValue;

    $('body').on('click', replaceLoaderTargetValue + ' nav.pagy-bootstrap-nav a', function(event){
      controller.setSpinner(replaceLoaderTargetValue);

      $('body').off('click', replaceLoaderTargetValue + ' nav.pagy-bootstrap-nav a'); // Avoid compounding events after replace.

      var url = $(this).attr('href');
      controller.loadContent(url, replaceTargetValue);

      event.preventDefault();
    });
  }

  disconnect(){
    $('body').off('click', this.replaceLoaderTargetValue + ' nav.pagy-bootstrap-nav a'); // Avoid compounding events after replace.
  }

  setSpinner(loadingTarget) {
    $(loadingTarget).html('<h3 class="text-center p-5"><i class="fad fa-spinner-third fa-spin d-inline-block text-secondary fa-2x"></i></h3>');
  }

  loadContent(url, updateTarget) {
    $.ajax({
      url: url,
      type: 'get',
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          localStorage.getItem("bp-x-csrf-token")
        );
      },
      dataType: "script",
      success: (response) => {
        $(updateTarget).html(response);

        $('[data-toggle="tooltip"]').tooltip();
      },
      error: (response) => {
        window.notyf.error("Something went wrong when loading your request. Please refresh the page or try again.");
      },
    });
  }
}