import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    this.setupSubmitEvent();
  }

  closeEdit(event) {
    var timeSlotDate = this.element.dataset.date;
    var scheduleId = this.element.dataset.scheduleId;

    var timeSlotsContainer = $('.time-slots[data-schedule-id=' + scheduleId + '][data-date=' + timeSlotDate + ']');

    timeSlotsContainer.find('.time-slots-show').show();
    timeSlotsContainer.find('.time-slots-edit').hide();
  }

  edit(event) {
    var timeSlotDate = this.element.dataset.date;
    var scheduleId = this.element.dataset.scheduleId;

    var timeSlotsContainer = $('.time-slots[data-schedule-id=' + scheduleId + '][data-date=' + timeSlotDate + ']');

    timeSlotsContainer.find('.time-slots-show').hide();
    timeSlotsContainer.find('.time-slots-edit').show();
  }

  setupSubmitEvent() {
    var form = $(this.element).find('form');
    var thisController = this;

    form.submit(function(event){
      event.preventDefault();

      $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        success: function(data) {
          thisController.rebuildTimeSlots(data.time_slots);
          thisController.closeEdit(event);
        },
        error: function(data) {
          window.notyf.error({
            message: "Please enter a valid range - for eg.<br />9:00am-12:30pm,<br />1:30pm-5pm",
            duration: 4500
          });
        },

      });

    })
  }

  rebuildTimeSlots(time_slots) {
    var timeSlotContent = '<i class="fas fa-pencil float-right"></i>';
    var timeSlotDate = this.element.dataset.date;
    var scheduleId = this.element.dataset.scheduleId;
    var timeSlotsContainer = $('.time-slots[data-schedule-id=' + scheduleId + '][data-date=' + timeSlotDate + ']');

    if(time_slots.length === 0) {
      timeSlotContent += '<div class="text-center"> - </div>';
    } else {
      _.each(time_slots, function(time_slot) {
        timeSlotContent += '<div class="time-slot">' + time_slot + '</div>';
      });
    }

    timeSlotsContainer.find('.time-slots-show').html(timeSlotContent);
  }

}