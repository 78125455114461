onmount = require("onmount");

function handleUserChange() {
  const selectedUser = document.querySelector("#js-booking-provider select").value;
  const selectedOpts = [...document.querySelector("#js-booking-secondary-providers select").options].filter(x => x.selected);

  for (opt of selectedOpts) {
    if (!$("#js-booking-provider select").prop('disabled') && opt.value == selectedUser) {
      opt.selected = false;
    }
  }
  $(".selectpicker").selectpicker("refresh");
}

onmount("#js-booking-secondary-providers select, #js-booking-provider select", function() {

  this.addEventListener("change", function(event) {
    handleUserChange();
  });
});

$(document).on("turbolinks:load", function () {
  if ($(".js-service-menu").is("*")) {
    load_calendar();
    doubleBookingCheck();
    loadFlatpickr();

    fields = ["#price_input", "#price_text", ".js-duration"];

    for (field in fields) {
      $(field).val("").prop("readonly", true).text("").trigger("change");
    }

    if($('#booking_series_id').val() !== "" && $('#booking_series_id').val() !== "undefined") {
      var series_id = $('#booking_series_id').val();

      $(".js-service-menu option[data-series-id=" + series_id + "]").prop('selected', 'selected');
      $(".selectpicker").selectpicker("refresh");
    }

    $(".js-service-menu").on("change", function () {
      var new_record = $(this).data("new-record");
      var selected = $(this).find(":selected");
      var service_name = $(selected).data("service-name");
      var service_type = $(selected).data("service-type");
      var service_id = $(selected).val();
      var series_id = $(selected).data("series-id");
      var default_user_id = $(selected).data("default-user-id");
      var client_id = $("#js-client_select").val();
      var booking_id = $(this).data("booking-id");
      var recurring_param_set = $(this).data("recurring-param-set");
      var start_at_date_param = this.dataset.startAtParam;
      var start_at_time_param = this.dataset.startAtTimeParam;
      var duration_param = this.dataset.durationParam;
      var selected_addon_ids = $(selected).data("addon-ids");

      if($('.js-duplicate-series-dates').is('*')){
        var duplicate_series_dates_param = $('.js-duplicate-series-dates').val();
      } else {
        var duplicate_series_dates_param = null;
      }

      var multi_client = $(selected).data("multi-client");

      if (new_record) {
        var price = $(selected).data("price");
      } else {
        var price = $("#price_input").val();
        var duration = $(".js-duration").val();
        var time = $(".timepicker").val();
        var date = $(".datepicker").val();
      }

      $(".js-remove-text").fadeOut();

      $(".service-name").val(service_name);
      $(".service-name").click();

      $("#price_input").val(price);
      $("#booking_series_id").val(series_id);

      $('#client_limit_input').on('change', () => { doubleBookingCheck(); });

      // Set the user field to the service's default user, see
      // app/helpers/service_helper.rb on line 44 where it refers to the
      // data-default-user-id field:
      //
      // https://github.com/WeAreBarNone/BusyPaws.app/blame/3d8f6c9ef8fc305986dded99d19d514ac7396888/app/helpers/service_helper.rb#L44
      if (
        default_user_id !== undefined &&
        default_user_id !== "" &&
        new_record
      ) {
        $("select.js-user-select").val(default_user_id);
        $(".selectpicker").selectpicker("refresh");
      }

      if (service_type == "boarding") {
        $("#js-booking-provider").slideUp("fast");
        $("#js-booking-provider select").prop('disabled', true);
        $("#js-booking-secondary-providers").slideUp("fast");
        $("#booking_user_id").prop('disabled', true);
      } else if (service_type == "daycare") {
        $("#js-booking-provider").slideUp("fast");
        $("#js-booking-provider select").prop('disabled', true);
        $("#js-booking-secondary-providers").slideDown("fast");
        $("#booking_user_id").prop('disabled', true);
      } else {
        $("#js-booking-provider").slideDown("fast");
        $("#js-booking-provider select").prop('disabled', false);
        $("#booking_user_id").prop('disabled', false);

        if($("#js-booking-secondary-providers").hasClass('multiple-users')){
          $("#js-booking-secondary-providers").slideDown("fast");
        } else {
          $("#js-booking-secondary-providers").slideUp("fast");
        }

        $(".selectpicker").selectpicker("refresh");
      }

      for (field in fields) {
        $(field).val("").prop("readonly", false).text("").trigger("change");
      }

      if (multi_client == true) {
        $("#js-client_select").prop("disabled", true);
        $(".js-individual-type-booking-ui input").prop("disabled", true);
        $("#customer, .js-invoice-and-credits").slideUp("fast");
        $(".client-limit-input-container").show();
        $(".client-limit-input-container")
          .find("input")
          .prop("value", $(selected).data("multi-client-limit"));


        $("#js-booking-location-select option[value='client_address']").hide();
        $("#js-booking-location-select").selectpicker('refresh');
      } else {
        $("#js-client_select").prop("disabled", false);
        $(".js-individual-type-booking-ui input")
          .not(".js-new-client-field")
          .not(".js-new-pet-field")
          .prop("disabled", false);
        $("#customer, .js-invoice-and-credits").slideDown("fast");
        $(".client-limit-input-container").hide();

        if (client_id && client_id != "new_client") {
          data = $(".js-new-and-edit-booking-form").serializeArray();

          $.ajax({
            url: "/business/bookings/refresh_details",
            type: "GET",
            data: data,
            dataType: "script",
          });
        }
      }

      if(multi_client == true || service_type == 'boarding') {
        if($("#js-booking-location-select option[value='client_address']:selected").length == 1) {
          $("#js-booking-location-select").val('facility_address');
        }

        $("#js-booking-location-select option[value='client_address']").hide();
        $("#js-booking-location-select").selectpicker('refresh');

      } else {
        $("#js-booking-location-select option[value='client_address']").show();
        $("#js-booking-location-select").selectpicker('refresh');
      }

      if (service_type == "boarding") {
        $("#room-list").slideUp("fast", function () {
          $("#kennel-list a").prop("disabled", false);
          $("#room-list a").prop("disabled", true);
          $("#kennel-list").slideDown("fast");
        });

        $(".js-price-text").text("Nightly Price");
      } else {
        $("#kennel-list").slideUp("fast", function () {
          $("#room-list a").prop("disabled", false);
          $("#kennel-list a").prop("disabled", true);
          $("#room-list").slideDown("fast");
        });

        $(".js-price-text").text("Price");
      }

      $(".js-new-and-edit-booking-form").trigger("change");

      if(selected_addon_ids === undefined || selected_addon_ids === '') {
        selected_addon_ids = [];
      }

      if(!multi_client && selected_addon_ids.length > 0) {
        $('#js-addons-container').fadeIn();
        $('#addon-ids').val("[]");
        $('.js-addon-btn').removeClass('btn-primary');
        $('.js-addon-btn').addClass('btn-light');
        $('.js-addon-btn-container').hide();

        $(selected_addon_ids).map(function(index, addon_id) {
          $('.js-addon-btn-container[data-addon-id="' + addon_id + '"][data-service-id="' + service_id + '"]').show();
        });

      } else {
        // Uncheck all add-ons and reset their state.
        $('#js-addons-container').hide();
        $('#addon-ids').val("[]");
        $('.js-addon-btn').removeClass('btn-primary');
        $('.js-addon-btn').addClass('btn-light');
      }

      $.ajax({
        url: "/business/bookings/render_date_selects",
        type: "GET",
        data: {
          service_id: service_id,
          series_id: series_id,
          booking_id: booking_id,
          recurring: recurring_param_set,
          start_at_date: start_at_date_param,
          start_at_time: start_at_time_param,
          duration: duration_param,
          duplicate_series_dates: duplicate_series_dates_param
        },
        dataType: "script",
        success: () => {
          $('[data-toggle="tooltip"]').tooltip();

          if (!new_record) {
            $(".js-duration").val(duration);
            $(".timepicker").val(time);
            $(".datepicker").val(date);
            $(".datepicker").data("default", Date.parse(date));
          }

          loadFlatpickr();

          if (service_type != "boarding") {
            load_calendar();
          } else {
            window.loadTimepicker();
          }

          if(series_id) {
            $('#js-series-recurring-btn, #js-series-manual-btn').on('click', function(e){
              e.preventDefault();
              button = $(this);

              if(!button.hasClass('active')) {
                $('#js-series-recurring-btn, #js-series-manual-btn').removeClass('active')
                button.addClass('active');

                if(button.data('target') == 'manual'){
                  $('#js-recurring-selection').hide();
                  $('#js-recurring-selection input').prop('disabled', true);
                  $('#js-manual-selection input').prop('disabled', false);
                  $('#js-manual-selection').show();

                } else {
                  $('#js-recurring-selection input').prop('disabled', false);
                  $('#js-recurring-selection').show();
                  $('#js-manual-selection').hide();
                  $('#js-manual-selection input').prop('disabled', true);
                }
              }
            });
          }

          onmount(); // this is needed to register newly visible UI - this log message is emitted 'binding a fun to this #recurring-btn.click' from pages/booking_recurring_partial.js
        },
      });
    });

    $('#js-booking-location-select').on("change", function(event){
      var bookingAddressType = $(this).find('option:selected').data('bookingAddressType');
      var savedAddressId = $(this).find('option:selected').data('savedAddressId');

      $('#js-booking-address-type').val(bookingAddressType);

      if(bookingAddressType == 'saved_address' && savedAddressId == 'new') {
        $('#js-new-saved-address-fields').show();
        $('#js-new-saved-address-fields input[type=text]').attr('required', 'required');
      } else {
        $('#js-new-saved-address-fields').hide();
        $('#js-new-saved-address-fields input[type=text]').removeAttr('required');
      }

      $('#js-new-client-address-fields, #js-edit-client-address-fields').hide();
      $('#js-new-client-address-fields input[type=text], #js-edit-client-address-fields input[type=text]').removeAttr('required');


      if(bookingAddressType == 'client_address') {
        if($('#js-client_select').val() == 'new_client') {
          $('#js-new-client-address-fields').show();
          $('#js-new-client-address-fields input[type=text]').attr('required', 'required');
        } else {
          $('#js-edit-client-address-fields').show();
          $('#js-edit-client-address-fields input[type=text]').attr('required', 'required');
        }
      }

    });

    $('.js-addon-btn').on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();

      if($(this).hasClass('btn-light')){
        $(this).removeClass('btn-light');
        $(this).addClass('btn-primary');
      } else{
        $(this).removeClass('btn-primary');
        $(this).addClass('btn-light');
      }

      var selected_addon_ids = [];

      $('.js-addon-btn.btn-primary:visible').each(function() {
        selected_addon_ids.push($(this).data('addon-id'));
      });

      $('#addon-ids').val(JSON.stringify($.unique(selected_addon_ids)));
      $('#addon-ids').trigger('change');

      var service_duration = $(".js-service-menu").find(":selected").data('duration');

      var pet_count_multiple = $('.btn-pet-select-checkbox:checked').length;

      if(pet_count_multiple === 0) {
        pet_count_multiple = 1;
      }

      $('.js-addon-btn.btn-primary').each(function() {
        if($(this).data('addDurationToBooking')){
          service_duration += $(this).data('duration') * pet_count_multiple;
        }
      });

      $(".js-duration").val(service_duration);
    });

    $('body').on('change', '.btn-pet-select-checkbox', function (event) {
      event.preventDefault();
      event.stopPropagation();

      var service_duration = $(".js-service-menu").find(":selected").data('duration');

      var pet_count_multiple = $('.btn-pet-select-checkbox:checked').length;

      if(pet_count_multiple === 0) {
        pet_count_multiple = 1;
      }

      if ($('.js-addon-btn.btn-primary').is('*')) {
        $('.js-addon-btn.btn-primary').each(function() {
          if($(this).data('addDurationToBooking')){
            service_duration += $(this).data('duration') * pet_count_multiple;
          }
        });
  
        $(".js-duration").val(service_duration);
      }
    });

    $("a.new_pet_btn").on("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      $("a.new_pet_btn").slideUp("fast");
      $("#new_pet input").prop("disabled", false);
      $("#new_pet").slideDown("fast");
    });

    if(($(".js-service-menu").find(":selected").data('series') === true) && ($(".js-service-menu").find(":selected").val() !== '')) {
      setTimeout(function () {
        $(".js-service-menu").trigger('change');
      }, 500);
    }
  }

  if ($("#recurring-booking").is("*")) {
    $(
      '[name="booking[price]"], [name="booking[service_id]"], [name="booking[client_limit]"], [name="booking[user_id]"]'
    ).on("change", function () {
      let form = document.querySelector("form.js-new-and-edit-booking-form");
      $.ajax({
        url: "/business/booking_conflicts/check_recurring_update_changes",
        type: "GET",
        data: $(form).serialize(),
        dataType: "script",
        success: () => {
          // use this callback to run additional javascript after (or before?) the remote js has evaluated from `url`
        },
      });
    });
  }

  if ($(".js-location-button").is("*")) {
    $(".js-location-button").on("click", (e) => {
      e.preventDefault();

      var button = e.target;
      var location_id = $(button).data("location-id");
      var location_html_select_content = $(button).data('location-html-select-content');
      var location_name = $(button).data('location-name');

      $(".location-id").val(location_id);

      $(".js-location-button").removeClass("active");
      $(button).addClass("active");

      $(".js-location-resource-button").hide();
      $(
        ".js-location-resource-button[data-location-id='" + location_id + "']"
      ).show();


      $("#js-booking-location-select").selectpicker("destroy");

      $('#js-booking-location-select option[value="facility_address"]').attr('data-content', location_html_select_content);
      $('#js-booking-location-select option[value="facility_address"]').html(location_name + ' Facility Address');

      $("#js-booking-location-select").selectpicker();
    });
  }

  if ($(".js-location-resource-button").is("*")) {
    $(".js-location-resource-button, .js-location-resource-button").on(
      "click",
      (e) => {
        e.preventDefault();

        var button = e.target;
        var location_resource_id = $(button).data("location-resource-id");
        var kennel = $(button).data("kennel") == true;
        $(".location-resource-id").val(location_resource_id);

        $(".js-location-resource-button").removeClass("active");

        if (button.tagName == "SMALL") {
          $(button).parent().addClass("active");
        } else {
          $(button).addClass("active");
        }
        $(".js-new-and-edit-booking-form").trigger("change");

        if(kennel && window.boardingRangeDatePicker != undefined) {
          var selectedDates = window.boardingRangeDatePicker.selectedDates;
          if(selectedDates.length == 2) {
            updateKennelAvailability(selectedDates);
          }
        }
      }
    );
  }

});

function load_calendar() {
  if ($(".js-date-calendar").is("*")) {
    var fullCalendars = [];
    var booking_ids = [];

    $(".js-date-calendar").each(function (index, calendar) {
      var date_wrapper = $(calendar).parents(".js-date-wrapper");
      booking_ids[index] = date_wrapper.find(".datepicker").data("booking-id");
      var datepickerInput = date_wrapper.find(".datepicker");
      var timepickerInput = date_wrapper.find(".timepicker");
      var durationInput = date_wrapper.find(".js-duration");

      if (datepickerInput.val()) {
        var js_date = new Date(datepickerInput.val());
        var date = moment(js_date);
        var end_time = moment(js_date);
        var time = moment(timepickerInput.val(), "HH:mm A");
        var duration = durationInput.val();
      } else {
        var js_date = new Date();
        var date = moment(js_date);
        var end_time = moment(js_date);
        var time = moment("10:00 AM", "HH:mm A");
        var duration = 60;
      }

      date.set("hour", time.hour());
      date.set("minute", time.minute());
      end_time.set("hour", time.hour());
      end_time.set("minute", time.minute());

      end_time.add(duration, "minutes");

      var scroll_time = time.subtract(2, "hours");

      if (
        scroll_time.format("HH") == "22" ||
        scroll_time.format("HH") == "23"
      ) {
        scroll_time = "00:00 AM";
      } else {
        scroll_time = scroll_time.format("HH:mm A");
      }

      fullCalendars[index] = new FullCalendar.Calendar(calendar, {
        timeZone: null, // IMPORTANT: Use timezone from API
        plugins: ["timeGrid"],
        defaultDate: date.format(),
        header: false,
        columnHeader: false,
        columnHeaderFormat: {
          weekday: "long",
          month: "long",
          day: "numeric",
          omitCommas: false,
          },
        defaultView: "timeGridDay",
        allDaySlot: false,
        nowIndicator: true,
        height: 350,
        scrollTime: scroll_time,
        eventSources: [
            {
              url: '/business/calendars/calendar.json',
              extraParams: function() {
                return {
                  fullcalendar: true,
                  exclude_booking_id: booking_ids[index],
                  facility_id: $('.location-id').val()  
                  }
                }
            }
          ],
        loading: function (isLoading) {
          if (fullCalendars[index]) {
            var event = fullCalendars[index].getEventById(booking_ids[index]);
            if (event === null) {
              var event = {
                id: booking_ids[index],
                title: "(Placeholder)",
                start: date.format(),
                end: end_time.format(),
                classNames: ["bg-tertiary"],
                borderColor: "#fff",
              };

              fullCalendars[index].addEvent(event); // when we call getEventById with 'temp', we'll get the event back now
              setTitleOfEvent(booking_ids[index], fullCalendars[index]);

              var new_hour = date.subtract(2, "hours");

              if (
                new_hour.format("HH") == "22" ||
                new_hour.format("HH") == "23"
              ) {
                fullCalendars[index].scrollToTime("00:00 AM");
              } else {
                fullCalendars[index].scrollToTime(new_hour.format("HH:mm A"));
              }
              fullCalendars[index].render();
            } else {
              if (event) {
                event.setProp("classNames", ["bg-tertiary"]);
                event.setProp("borderColor", "#fff");
              } else {
                console.log(
                  `WARNING! no event found in fullCalendars[index].getEventById(${booking_ids[index]} bookings_ids[index])`
                );
              }
            }

            doubleBookingCheck();
          }
        },
      });

      fullCalendars[index].render();

      setTimePicker(timepickerInput, fullCalendars[index]);

      $("#js-client_select, #js-service_select, .service-name, .js-location-resource-button, .js-location-button")
        .add(datepickerInput)
        .add(timepickerInput)
        .add(durationInput)
        .on("change click keyup", function (_e) {
          changeDate(datepickerInput, timepickerInput, fullCalendars[index]);
          setDateAndTimeOfEvent(
            booking_ids[index],
            datepickerInput,
            timepickerInput,
            durationInput,
            fullCalendars[index]
          );
          setTitleOfEvent(booking_ids[index], fullCalendars[index]);
          doubleBookingCheck();
          fullCalendars[index].setOption("height", 350);
          fullCalendars[index].render();
        });

        $(".js-location-button").on("click", function() {
          console.log('reloading events')
          fullCalendars[index].refetchEvents();
        });

      
      if ($(".js-series-tab-nav").is("*")) {
        var stepNumber = datepickerInput.parents(".tab-pane").data("step");
        var stepTab = $(
          'a.js-series-nav-link[href="#step_' + stepNumber + '"]'
        );

        stepTab.on("click", function () {
          fullCalendars[index].setOption("height", 350);
        });
      }
    });
  }
}

function setTitleOfEvent(booking_id, fullCalendar) {
  var multi_client = $(".js-service-menu")
    .find(":selected")
    .data("multi-client");

  if ($("#js-client_select").val()) {
    var client = $("#js-client_select").find(":selected").text();
  } else {
    var client = "Client";
  }

  if ($(".service-name").val()) {
    var service = $(".service-name").val();
  } else {
    var service = "Service";
  }

  var event = fullCalendar.getEventById(booking_id);

  if (multi_client) {
    event.setProp("title", service + " (This Booking)");
  } else {
    event.setProp("title", service + " with " + client + " (This Booking)");
  }
}

function setDateAndTimeOfEvent(
  booking_id,
  datepickerInput,
  timepickerInput,
  durationInput,
  fullCalendar
) {
  var js_start_date = new Date(datepickerInput.val());
  var date = moment(js_start_date);
  var end_time = moment(js_start_date);
  var time = moment(timepickerInput.val(), "HH:mm A");

  if (durationInput.val()) {
    var duration = durationInput.val();
  } else {
    var duration = 60;
  }

  date.set("hour", time.hour());
  date.set("minute", time.minute());
  end_time.set("hour", time.hour());
  end_time.set("minute", time.minute());
  end_time.add(duration, "minutes");

  var event = fullCalendar.getEventById(booking_id);

  event.setDates(date.format(), end_time.format());
}

function changeDate(dateInput, timepickerInput, fullCalendar) {
  var js_start_date = new Date(dateInput.val());
  var start = moment(js_start_date).format();
  var time = moment(timepickerInput.val(), "HH:mm A");

  if ($(".js-series-tab-nav").is("*")) {
    var stepNumber = dateInput.parents(".tab-pane").data("step");
    updateDateText(stepNumber, start, time);
  }

  // Handles switching between series and individual bookings;
  if(start !== 'Invalid date'){
    fullCalendar.gotoDate(start);
    var new_hour = time.subtract(2, "hours");

    if (new_hour.format("HH") == "22" || new_hour.format("HH") == "23") {
      fullCalendar.scrollToTime("00:00");
    } else {
      fullCalendar.scrollToTime(new_hour.format("HH:mm"));
    }
  }
}

function updateDateText(stepNumber, date, time) {
  var dateSpan = $("#js-step-" + stepNumber + "-date");

  if(date !== "Invalid date"){
    var dateTime = moment(date).format("YYYY-MM-DD") + " " + time.format("HH:mm");
    dateSpan.removeClass("text-warning");
    dateSpan.text(moment(dateTime).format("ddd, MMM D, h:mma"));
  }
}

function loadFlatpickr() {
  if ($(".datepicker-range").is("*")) {
    window.boardingRangeDatePicker = flatpickr(".datepicker-range", {
      autoclose: true,
      inline: true,
      mode: "range",
      monthSelectorType: "static",
      dateFormat: "D d M Y",
      allowInput: true,
      onReady: function () {
        start = $(this.element).data("start");
        end = $(this.element).data("end");

        this.setDate([start, end], false, "Y-m-d");
      },
      onChange: function (dates) {
        updateKennelAvailability(dates);
      },
    });

    var existing_start = $(".datepicker-range").data("start");
    var existing_end = $(".datepicker-range").data("end");

    if(existing_start != undefined && existing_end != undefined) {
      updateKennelAvailability([existing_start, existing_end])
    }

  } else {
    flatpickr(".datepicker", {
      autoclose: true,
      inline: true,
      monthSelectorType: "static",
      dateFormat: "D d M Y",
      allowInput: true,
      onReady: function () {
        date = $(this.element).data("default");
        this.setDate(date, false, "Y-m-d");
      },
    });
  }

  // for recurring date select
  flatpickr(".flatpickr", {
    autoclose: true,
    monthSelectorType: "static",
    dateFormat: "Y-m-d",
    allowInput: false,
  });

  var withMinDate = document
    .querySelectorAll(".flatpickr-with-min-and-max-date")
    .forEach(function (html_input) {
      flatpickr(html_input, {
        autoclose: true,
        monthSelectorType: "static",
        dateFormat: "Y-m-d",
        maxDate: html_input.dataset["maxdate"],
        minDate: html_input.dataset["mindate"],
        allowInput: false,
      });
    });
}

function setTimePicker(timepickerInput, fullCalendar) {
  timepickerInput.timepicker({
    interval: "15",
    dynamic: false,
    change: function (time) {
      var element = $(this);
      var time_scroll = timepickerInput.val();

      fullCalendar.scrollToTime(
        moment(time_scroll, "hh:mm A").subtract(2, "hours").format("HH:mm")
      );
      element.trigger("change");
    },
  });
}

function doubleBookingCheck() {
  if ($("#js-double-booking-warning").is("*")) {
    var form = $(".js-new-and-edit-booking-form");
    var warningDiv = $("#js-double-booking-warning");
    var double_booking_check_url = warningDiv.data("checkUrl");
    var booking_id = warningDiv.data("bookingId");

    $.ajax({
      url: double_booking_check_url,
      type: "post",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          localStorage.getItem("bp-x-csrf-token")
        );
      },
      data: $.param(
        _.reject(
          $(form)
            .serializeArray()
            .concat({ name: "booking_id", value: booking_id }),
          (el) => {
            return el["name"] == "_method";
          }
        )
      ),
      dataType: "script",
      success: (response) => {
        window.response = response;
      },
      error: (_response) => {
        console.log("Error: Unable to perform double booking check.");
      },
    });
  }
}

function updateKennelAvailability(dates) {
  var start_at = dates[0];
  var end_at = dates[1];
  var form = $(".js-new-and-edit-booking-form");

  if (start_at && end_at) {
    var data = form.serializeArray();

    $.ajax({
      url: "/business/bookings/update_kennel_availability",
      type: "GET",
      data: data,
      dataType: "script",
      error: (_response) => {
        console.log("Error: Unable to update kennel availability.");
      },
    });
  }
}
