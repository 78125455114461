$(document).on("turbolinks:load", function () {
  if ($('#js-pos-device-price').is('*')) {

    var currencySymbol = $('#js-pos-device-price').data('currency-symbol');

    $.ajax({
      url: "/business/admin/pos_devices/get_available_pos_sku.js",
      type: "GET",
      dataType: "script",
      success: (response) => {
        var json = JSON.parse(response);
        var unitPrice = json.price;
        var taxPer = json.tax_per;
        var quantity = $('#js-device-quantity').val();
        var countryCode = json.country;

        $('#js-pos-device-price').fadeOut('fast', function(){

          $('#js-pos-device-price').html(currencySymbol + unitPrice);
          $('#js-pos-device-price').fadeIn('fast');

          updatePricing(unitPrice, taxPer, currencySymbol, countryCode);

          $('#js-device-quantity').on('change keyUp', function(){
            updatePricing(unitPrice, taxPer, currencySymbol, countryCode);
          });


        });
      }
    });

    var locationId = $('#pos_device_location_id').val();
    getAddress(locationId);

    if ($('select#pos_device_location_id').is('*')) {
      $('select#pos_device_location_id').on('change', function(){
        var locationId = $(this).val();
        getAddress(locationId);
      })
    }
  }
});

function updatePricing(unitPrice, taxPer, currencySymbol, countryCode) {
  var quantity = $('#js-device-quantity').val();

  if(taxPer > 0) {
    $('#js-subtotal-price').html(currencySymbol + (unitPrice * quantity).toFixed(2));
    $('#js-taxes').html(currencySymbol + (taxPer * quantity).toFixed(2));
    $('#js-total-price').html(currencySymbol + ((unitPrice + taxPer) * quantity).toFixed(2));
    $('#js-device-pricing-table, #js-subtotal-row, #js-taxes-row, #js-total-row').removeClass('d-none');

  } else {
    $('#js-total-price').html(currencySymbol + (unitPrice * quantity).toFixed(2));
    $('#js-device-pricing-table, #js-total-row').removeClass('d-none');
  }

}

function getAddress(locationId) {
  $.ajax({
    url: '/business/admin/pos_devices/get_address.js',
    type: "GET",
    dataType: "script",
    data: { location_id: locationId},
    error: function(){
      $('#js-address-display').slideUp('fast', function(){
        $('#js-address-display').html('A valid address must be assigned to your account to order a PoS Device.');
        $('#js-address-display').addClass('text-danger');
        $('#js-address-display').slideDown('fast');
      });
    }
  });
}
