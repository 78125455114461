// Docs: https://github.com/sampotts/plyr
// Docs: https://github.com/dailymotion/hls.js

import Hls from "hls.js";
import Plyr from "plyr";
onmount = require("onmount");

onmount(".bp-video-player", function () {
  const video = this;
  const defaultOptions = {};
  var complete_url = $(video).data("complete-url");
  var hls_url = $(video).data("hls");

  if (Hls.isSupported() && hls_url) {
    const hls = new Hls();
    var hls_url = $(video).data("hls");
    hls.loadSource(hls_url);

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      const availableQualities = hls.levels.map((l) => l.height);

      defaultOptions.quality = {
        default: availableQualities[availableQualities.length - 1],
        options: availableQualities,
        forced: true,
        onChange: (e) => updateQuality(e),
      };

      const player = new Plyr(video, defaultOptions);
      addListener(player, complete_url);
    });

    hls.attachMedia(video);
    window.hls = hls;
  } else {
    const player = new Plyr(video, defaultOptions);
    addListener(player, complete_url);
  }

  function addListener(plyr, complete_url) {
    if (complete_url) {
      var updated = false;
      plyr.on("timeupdate", function () {
        if (plyr.currentTime / plyr.duration >= 0.9 && !updated) {
          updated = true;
          $.ajax({
            url: complete_url,
            type: "POST",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                localStorage.getItem("bp-x-csrf-token")
              );
            },
            data: {},
            dataType: "script",
          });
        }
      });
    }
  }

  function updateQuality(newQuality) {
    window.hls.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        window.hls.currentLevel = levelIndex;
      }
    });
  }
});
