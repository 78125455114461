$(document).on("turbolinks:load", function () {

  bind_service_select_change();

});

function bind_service_select_change() {
  $('.js-select-waitlist-service').on('change', function(){
    var id = $(this).find(':selected').val();
    var service_id = $('#waitlist_service_id');
    var series_id = $('#waitlist_series_id');

    console.log('service_id', service_id);
    console.log('series_id', series_id);

    if ($(this).find(':selected').data('series') ) {
      service_id.val('');
      series_id.val(id.replace('[series]', ''));
    } else {
      service_id.val(id);
      series_id.val('');
    }

  });
}

