$(document).on("turbolinks:load", function () {
  if ($('textarea.js-autogrow').is('*')) {
    $('textarea.js-autogrow').on('keydown', function(){
      autogrow($(this));
    });
  }
});

function autogrow(el) {
  el.css('min-height', '30px');
  el[0].style.setProperty('height', el[0].scrollHeight + 'px', 'important');
}