import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";

Dropzone.autoDiscover = false;
// import {
//   getMetaValue,
//   toArray,
//   findElement,
//   removeElement,
//   insertAfter,
// } from "helpers";
// app/javascript/helpers/index.js

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.unlockHiddenFileInputRequirement();
    this.bindEvents();
    this.prePopulateExistingFiles();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  prePopulateExistingFiles() {
    if (this.inputTarget.dataset.signedId != null) {
      // console.log("CREATING ALREADY UPLOADED CONTROLLER!");
      this.already_uploaded_controller = createAlreadyUploadedController(this);
      this.already_uploaded_controller.start();
    }
  }

  // Private
  hideFileInput() {
    // console.log("Running hideFileInput");
    if (!this.inputTarget.dataset.required_analyzed) {
      this.inputTarget.dataset.was_required =
        this.inputTarget.getAttribute("required");
      this.inputTarget.dataset.required_analyzed = true;
    }
    this.inputTarget.removeAttribute("required");
    this.inputTarget.disabled = "disabled";
    this.inputTarget.style.display = "none";
  }

  // enabling this element allows us to rely on the `required=required` html
  // setting to style the form when the user forgets to submit the element -
  // when we receive an uploaded file, we then switch off the file input and
  // rely on the new virtual ones we created per upload
  unlockHiddenFileInputRequirement() {
    // console.log("Running unlockHiddenFileInputRequirement");
    this.inputTarget.removeAttribute("disabled");
    if (
      this.inputTarget.dataset.was_required !== undefined &&
      this.inputTarget.dataset.was_required !== "null" &&
      this.inputTarget.dataset.was_required
    ) {
      this.inputTarget.required = "required";
    }

    // console.log("Disabled?", this.inputTarget.getAttribute("disabled"));
    // console.log("required?", this.inputTarget.getAttribute("required"));
  }

  bindEvents() {
    let $this = this;

    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        if (file.fakeFile == true) {
          this.hideFileInput();
          file.status = Dropzone.SUCCESS;
          this.dropZone.emit("success", file);
          this.inputTarget.disabled = true;
          this.inputTarget.form
            .querySelectorAll("[type='submit']")
            .forEach((item) => {
              item.disabled = false;
            });
          this.dropZone.emit("complete", file);
        } else if (
          file.accepted &&
          this.dropZone.files.length <= $this.maxFiles
        ) {
          // We need to remove the file when it's the hidden input created in the
          // already_uploaded_controller
          if ($this.already_uploaded_controller) {
            removeElement($this.already_uploaded_controller.hiddenInput);
          }

          this.hideFileInput();
          createDirectUploadController(this, file).start();
        } else if (!file.accepted) {
          window.notyf.error(`Sorry, this is an invalid filetype`);
          this.dropZone.removeFile(file);
        } else {
          window.notyf.error(
            `Sorry, you've exceeded the max number of files (${$this.maxFiles})`
          );
          this.dropZone.removeFile(file);
        }
      }, 100);
    });

    this.dropZone.on("removedfile", (file) => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
      }

      // We need to remove the file when it's the hidden input created in the
      // already_uploaded_controller
      if ($this.already_uploaded_controller) {
        $this.already_uploaded_controller.hiddenInput.value = null;
      }

      this.hideOrEnableHiddenInput();
    });

    this.dropZone.on("canceled", (file) => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
        file.controller.xhr.abort();
      }

      this.hideOrEnableHiddenInput();
    });
  }

  hideOrEnableHiddenInput() {
    if (this.dropZone.files.length == 0) {
      this.unlockHiddenFileInputRequirement();
    } else {
      this.hideFileInput();
    }
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class AlreadyUploadedController {
  constructor(source) {
    this.source = source;
  }

  start() {
    this.hiddenInput = this.createHiddenInput();
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    if (this.source.inputTarget.dataset.signedId != null) {
      input.value = this.source.inputTarget.dataset.signedId;
      let fakeFile = {
        name: this.source.inputTarget.dataset.friendlyFilename,
        size: this.source.inputTarget.dataset.byteSize,
        fakeFile: true,
      };
      // console.log("fakeFile", fakeFile);
      // console.log(
      //   "this.source.inputTarget.dataset.imageUrl",
      //   this.source.inputTarget.dataset.imageUrl
      // );
      this.source.dropZone.displayExistingFile(
        fakeFile,
        this.source.inputTarget.dataset.imageUrl
      );
    }

    // input.required = this.source.inputTarget.required;
    // console.log("newly created input's required=", input.required);
    insertAfter(input, this.source.inputTarget);
    return input;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    // input.required = this.source.inputTarget.required;
    // console.log("newly created input's required=", input.required);
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
    this.source.inputTarget.form
      .querySelectorAll("[type='submit']")
      .forEach((item) => {
        item.disabled = true;
      });
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    console.log("dropzone error!", error);
    this.source.dropZone.emit("complete", this.file);
    this.source.inputTarget.form
      .querySelectorAll("[type='submit']")
      .forEach((item) => {
        item.disabled = false;
      });
    window.notyf.error(
      "Sorry, there was a problem uploading your file - remove the file and try again"
    );

    // TODO: report to our js error handler honeybadger/logrocket that there was an error

    this.source.dropZone.removeFile(this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.inputTarget.disabled = true;

    this.source.inputTarget.form
      .querySelectorAll("[type='submit']")
      .forEach((item) => {
        item.disabled = false;
      });

    this.source.dropZone.emit("complete", this.file);

    if (this.source.dropZone.element.dataset.submitFormOnUpload == "true") {
      this.source.inputTarget.form.submit();
    }
  }
}

function createAlreadyUploadedController(source) {
  return new AlreadyUploadedController(source);
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
  });
}
