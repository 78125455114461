// This needs to be attached directly to a form to work.
onmount(".js-dirty-form-alert", function () {
  var formElement = $('.js-dirty-form-alert');
  var originalSerializedContent = formElement.serialize();

  window.dirtyFormCheck = function() {
    if(originalSerializedContent != formElement.serialize()){

      var uniqueId = new Date().getTime();
      var domId = "continue-after-dirty-form-" + uniqueId;
      var successLink = "<a href='" + event.data.url + "' id='" + domId + "' class='d-none' onclick=' window.dirtyFormCheck = function() {};'></a>";

      $('body').append(successLink);

      var continueLink = '#' + domId;

      console.log(continueLink)

      Rails.confirm("<h4>Leave page with unsaved changes?<br /><small>Leaving this page will delete all unsaved changes.</small></h4>", continueLink)

      event.preventDefault();
    }
  }

});

$(document).on('turbolinks:before-visit', function(){
  if(window.dirtyFormCheck != undefined) {
    window.dirtyFormCheck();
  }
});

$(document).on("turbolinks:before-cache", () => {
  window.dirtyFormCheck = function() { };
});

onmount();
