$(document).on("turbolinks:load", function () {
  if ($('.js-number-children').is('*')) {
    $('.js-init-numbering').on('click', function () {
      var button = $(this);
      setTimeout(function (){
        elements = button.parentsUntil('.js-number-children').find('.js-number-me');
        setSortOrder(elements);
      });
    });
  }
});

function setSortOrder(elements) {
  var i = 0;
  elements.each(function () {
    i++;
    $(this).val(i);
  });
}
