onmount("select.js-access-level-change", function () {
  this.dataset.oldValue = this.value;
  $(this).on("change", function (event) {
    if (
      (event.target.dataset.oldValue == "lite" &&
        event.target.value != "lite") ||
      (event.target.dataset.oldValue != "lite" && event.target.value == "lite")
    ) {
      $(".js-access-level-change").each(function () {
        event.target.dataset.oldValue = event.target.value;
        this.dataset.oldValue = event.target.value;
        $(this).selectpicker("val", event.target.value);
      });
      $(".js-access-level-change").selectpicker("refresh");
    }
  });
});
