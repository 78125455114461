import Tribute from 'tributejs';

$(document).on("turbolinks:load", function () {
  if ($('.js-select-message-template-service').is('*')) {
    bind_select_change();
  }

  if ($('.js-add-message-service').is('*')) {
    $('.js-add-message-service').on('after-load', bind_select_change);
  }

  if ($('.tribute-input').is('*')) {
    var elements = document.getElementsByClassName('tribute-input');
    bind_tribute(elements);
  }

  $('.js-add-sheduled-message-two').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    $('.js-add-sheduled-message-two').hide();
    $('.js-add-sheduled-message-two').siblings('.input-group').find('input').prop('disabled', false)
    $('.js-add-sheduled-message-two').siblings('.input-group').show();

    $('.js-add-sheduled-message-three').show();
  });

  $('.js-add-sheduled-message-three').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    $('.js-delete-message-two').hide();
    $('.js-add-sheduled-message-three').hide();
    $('.js-add-sheduled-message-three').siblings('.input-group').find('input').prop('disabled', false)
    $('.js-add-sheduled-message-three').siblings('.input-group').show();
  });

  $('.js-delete-message-two').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    $('.js-add-sheduled-message-two').siblings('.input-group').hide();
    $('.js-add-sheduled-message-three').hide();
    $('.js-add-sheduled-message-two').show();

    $('.js-add-sheduled-message-two').siblings('.input-group').find('input').val('');
  });

  $('.js-delete-message-three').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    $('.js-add-sheduled-message-three').siblings('.input-group').hide();
    $('.js-add-sheduled-message-three').show();
    $('.js-delete-message-two').show();

    $('.js-add-sheduled-message-three').siblings('.input-group').find('input').val('');
  });

  $('#js-schedule-unit-one-input, #js-schedule-unit-two-input, #js-schedule-unit-three-input').on('change', function(){
    var type = $(this).find(':selected').val();
    var max_value = 72;

    if (type == 'days') {
      max_value = 30;
    }

    $(this).parent().siblings('input').prop('max', max_value);

  });
});

function bind_select_change() {
  $('.js-select-message-template-trigger-type').on('change', function(){
    var message_trigger_type = $('.js-select-message-template-trigger-type').find(':selected').val();

    if(message_trigger_type.includes('package')){
      $('.js-message-template-trigger-type-package-options').show();
      $('.js-message-template-trigger-type-service-options').hide();
    } else {
      $('.js-message-template-trigger-type-package-options').hide();
      $('.js-message-template-trigger-type-service-options').show();
    }

    if(message_trigger_type.includes('booking_reminder') || message_trigger_type.includes('booking_followup')){
      $('#js-scheduled-message-one-inputs').find('input').prop('disabled', false);
      $('#js-scheduled-message-inputs').show();
    } else {
      $('#js-scheduled-message-inputs').hide();
      $('#js-scheduled-message-inputs').find('input').prop('disabled', true);
    }
  });

  $('.js-select-message-template-content-type').on('change', function(){
    var message_content_type = $('.js-select-message-template-content-type').find(':selected').val();

    if(message_content_type == 'email') {
      $('#js-email-content-fields').find('input, textarea').prop('disabled', false);
      $('#js-sms-content-fields').hide();
      $('#js-sms-content-fields').find('input, textarea').prop('disabled', true);
      $('#js-email-content-fields').show();
    } else {
      $('#js-sms-content-fields').find('input, textarea').prop('disabled', false);
      $('#js-email-content-fields').hide();
      $('#js-email-content-fields').find('input, textarea').prop('disabled', true);
      $('#js-sms-content-fields').show();
    }

  });

  $('.js-select-message-template-service').on('change', function(){
    var id = $(this).find(':selected').val();
    var service_id = $(this).parents('.js-delete-target').find("input[name*='service_id']");
    var series_id = $(this).parents('.js-delete-target').find("input[name*='series_id']");

    if ( $(this).find(':selected').data('series') ) {
      service_id.val('');
      series_id.val(id.replace('[series]', ''));
    } else {
      service_id.val(id);
      series_id.val('');
    }
  });

}

function bind_tribute(elements) {
  var dynamic_vars = [
    { value: 'first_name', key: 'Client First Name' },
    { value: 'last_name', key: 'Client Last Name' },
    { value: 'client_email', key: 'Client Email' },
    { value: 'client_phone', key: 'Client Phone' },
    { value: 'credit_service_type', key: 'Credit Service Name' },
    { value: 'credit_expiration_date', key: 'Credit Expiration Date' },
    { value: 'company_name', key: 'Company Name' },
    { value: 'company_phone', key: 'Company Phone' },
    { value: 'form_name', key: 'Form Name' },
    { value: 'booking_pets', key: 'Registered Pets' },
    { value: 'booking_series_name', key: 'Booking Series Name' },
    { value: 'booking_service_name', key: 'Booking Service Name' },
    { value: 'booking_service_description', key: 'Booking Service Description' },
    { value: 'booking_provider', key: 'Booking Provider' },
    { value: 'booking_room', key: 'Booking Room' },
    { value: 'booking_location_name', key: 'Booking Location Name'},
    { value: 'booking_location_address', key: 'Booking Location'},
    { value: 'booking_facility_name', key: 'Booking Facility Name' },
    { value: 'booking_facility_address', key: 'Booking Facility Address' },
    { value: 'booking_user_conference_link', key: 'Booking Provider Conference Link' },
    { value: 'membership_name', key: 'Membership Name' },
    { value: 'membership_expiration_date', key: 'Membership Expiration Date' },
    { value: 'package_name', key: 'Package Name' },
    { value: 'pet_name', key: 'Pet Name' },
    { value: 'pet_age', key: 'Pet Age' },
    { value: 'vaccine_name', key: 'Vaccine Name' },
    { value: 'vaccine_expiration_date', key: 'Vaccine Expiration Date' }
  ]

  var tribute = new Tribute ({
    trigger: '{{',
    containerClass: 'dropdown-menu',
    itemClass: 'dropdown-item',
    values: dynamic_vars,
    replaceTextSuffix: '}}\n',
    noMatchTemplate: "<span class='ml-2'>No Match Found!</span>"
  });

  elements.forEach(function(element){
    tribute.attach(element);
  });
}
