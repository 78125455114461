import { Controller } from "stimulus";

export default class extends Controller {

  formExportTypeChange(event) {
    var exportTypeSelected = $(event.target).val();
    $('.export-type-options-container input[type=checkbox]:checked').click(); // Deselect from the other export type is selected
    $('.export-type-options-container').hide();
    $('.export-type-options-container[data-export-type=' + exportTypeSelected + ']').show();

    if(exportTypeSelected == 'products' || exportTypeSelected == 'credit_report') {
      $('.js-data-exports-date-range').hide();
    } else {
      $('.js-data-exports-date-range').show();
    }
  }

}