import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var controller = this;

    controller.checkForIndividualAvailabilities();

    $("body").on("change", ".js-user-select, .js-select-start-date, .js-select-start-time, #addon-ids", function(){
      controller.checkForIndividualAvailabilities();
    });

    $("body").on("click", ".js-location-button, .js-location-resource-button", function(){
      controller.checkForIndividualAvailabilities();
    });

    controller.setupShowNextAvailabilityBtn();
    controller.setupShowCalendarBtn();
    controller.setupFindNextAvailabilityBtn();
    controller.setupSearchForNextAvailabilityBtn();
    controller.setupSelectTimeSlotBtn();
  }

  checkForIndividualAvailabilities(){
    var form = $(".js-new-and-edit-booking-form");
    var requestDiv = $("#js-booking-availability-check");
    var availability_check_url = requestDiv.data("checkUrl");

    $.ajax({
      url: availability_check_url,
      type: "get",
      data: $.param($(form).serializeArray()),
      dataType: "script",
      success: (response) => {
        window.response = response;
      },
      error: (_response) => {
        console.log("Error: Unable to perform booking availability check.");
      },
    });
  }

  setupSelectTimeSlotBtn(){
    $("body").on("click", "#js-booking-availability-check .btn-select-time-slot", function (event) {
      var start_at_time = $(this).data('startAtTime');
      var start_at_date = $(this).data('startAtDate');
      var user_id = $(this).data('userId').toString();

      var calendar = flatpickr(".datepicker", {
            autoclose: true,
            inline: true,
            monthSelectorType: "static",
            dateFormat: "D d M Y",
            allowInput: true,
          });

      calendar.jumpToDate(start_at_date);
      calendar.setDate(start_at_date);

      $('.js-select-start-time').val(start_at_time);
      $('.js-select-start-time').trigger('click');

      $('#js-booking-availability-check .time-slot').removeClass('border-primary');

      $('#js-booking-availability-check .time-slot .add-icon').removeClass('d-none');
      $('#js-booking-availability-check .time-slot .selected-icon').addClass('d-none');

      if($('select.js-user-select').val() != user_id){
        $('.js-user-select').val(user_id).change();
      }

      $(this).closest('li').addClass('border-primary');
      $(this).find('.add-icon').addClass('d-none');
      $(this).find('.selected-icon').removeClass('d-none');

      event.preventDefault();
    });

  }

  setupShowNextAvailabilityBtn() {
    $("body").on("click", "#js-booking-availability-check .btn-check-next-available-tab", function (event) {
      $(this).addClass('active');
      $('#js-booking-availability-check .btn-check-calendar-tab').removeClass('active');

      $('#js-booking-availability-check .js-next-available-content').show();
      $('.js-date-calendar').hide();

      $('.js-individual-availability-preview-state').val('next-available');

      event.preventDefault();
    });
  }

  setupShowCalendarBtn(){
    $("body").on("click", "#js-booking-availability-check .btn-check-calendar-tab", function (event) {
      $(this).addClass('active');
      $('#js-booking-availability-check .btn-check-next-available-tab').removeClass('active');

      $('#js-booking-availability-check .js-next-available-content').hide();
      $('.js-date-calendar').show();

      $('.js-individual-availability-preview-state').val('calendar');

      // Trigger a redraw for Full Calendar UI
      window.dispatchEvent(new Event('resize'));

      event.preventDefault();
    });
  }

  setupFindNextAvailabilityBtn(){
    $("body").on("click", "#js-booking-availability-check .btn-find-next-available", function (event) {
      var page = $(this).data('page');
      var next_page = page + 1;
      var per_page = $(this).data('perPage');
      var total = $(this).data('total');

      var start_index = next_page * per_page;
      var end_index = start_index + per_page;

      $(this).prop('disabled', 'disabled');
      $(this).find('.loader').removeClass('d-none');

      if(start_index < total) {
        var range = _.range(start_index, end_index);

        $('.js-next-available-content .time-slot').addClass('d-none');

        $(this).data('page', next_page);

        _.each(range, function(index) {
          $('.js-next-available-content .time-slot[data-index=' + index + ']').removeClass('d-none');
        });

        var elem = $(this);

        setTimeout(function () {
          $(elem).removeAttr('disabled');
          $(elem).find('.loader').addClass('d-none');
        }, 100);

      } else {
        var next_availability_check_page_url = $(this).prop('href');

        $.ajax({
          url: next_availability_check_page_url,
          type: "get",
          dataType: "script",
          success: (response) => {
            window.response = response;
          },
          error: (_response) => {
            console.log("Error: Unable to perform booking availability check.");
          },
        });
      }

      event.preventDefault();
    });
  }

  setupSearchForNextAvailabilityBtn() {
    $("body").on("click", "#js-booking-availability-check #js-search-next-30-days", function (event) {
      var next_availability_check_page_url = $(this).prop('href');

      $.ajax({
        url: next_availability_check_page_url,
        type: "get",
        dataType: "script",
        success: (response) => {
          window.response = response;
        },
        error: (_response) => {
          console.log("Error: Unable to perform booking availability check.");
        },
      });

      event.preventDefault();
    });
  }

}