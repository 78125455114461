import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    var controller = this;

    $('body').on('mouseenter', 'a.js-load-clockinout-widget-on-hover', function(event){
	    fetch(controller.urlValue)
	      .then((response) => response.text())
	      .then(function (html) {
          $('#js-time-entries-clockin-widget').html(html);
          window.setupJSRemoteFormScript();

          $(".js-timer").each(function(){
            window.setupJSTimer(this);
          });
	      });
    });
  }

  disconnect(){
    $('body').off('mouseenter', 'a.js-load-clockinout-widget-on-hover'); // Avoid compounding events after replace.
  }
 }