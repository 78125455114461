$(document).on("turbolinks:load", function () {

  if ($('#js-stripe-account-balance').is('*')) {
    getStripeBalance();
  }

  if ($('#js-stripe-account-session').is('*')) {
    getStripeSession();
  }

  if ($('#stripe-card-elements').is('*')) {
    var cardButton = document.getElementById('stripe-submit-button');
    var resultContainer = document.getElementById('card-result');

    var cardWrapper = document.getElementById('card_wrapper');
    var cardSuccess = document.getElementById('card_success');

    var elements = stripe.elements();
    var cardElement = elements.create("card", {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#2a1b9a",
          color: "#6A1B9A",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#ced4da"
          },
          ":-webkit-autofill": {
            color: "#9a691b"
          }
        },
        invalid: {
          iconColor: "#f44455",
          color: "#f44455"
        }
      }
    });

    cardElement.mount('#stripe-card-elements');

    if($('#js-existing-card').is('*')) {
      var elements = stripe.elements({
      fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
          },
        ],
        locale: window.__exampleLocale
      });

      var verifyCardCvcElement = elements.create('cardCvc',{
        iconStyle: "solid",
        style: {
          base: {
            iconColor: "#2a1b9a",
            color: "#6A1B9A",
            fontWeight: 400,
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#ced4da"
            },
            ":-webkit-autofill": {
              color: "#9a691b"
            }
          },
          invalid: {
            iconColor: "#f44455",
            color: "#f44455"
          }
        }

      });

      verifyCardCvcElement.mount('#js-stripe-verify-cvc-element');
    }

    cardButton.addEventListener('click', function(event){
      event.preventDefault();
      changeLoadingState(true, '#stripe-submit-button');

      var stripeFunction = $(cardButton).data('stripe-function');

      if ( stripeFunction == 'paymentMethod' ) {
        createStripePaymentMethod();
      }  else if (stripeFunction == 'paymentMethodWithJsonResponse'){
        createStripePaymentMethodWithJsonResponse();
      } else if ( stripeFunction == 'paymentIntent') {
        chargeStripePaymentIntent();
      }
    });

  }

  if ($('#js-apply-discount-code-btn').is('*')) {

    $('#discount-code').keypress(function(e){
      if(e.keyCode==13) {
        e.preventDefault();
        $('#js-apply-discount-code-btn').click();
      }
    });

    $('#js-apply-discount-code-btn').on('click', function(e){
      e.preventDefault();
      applyDiscountCode();
    });
  }

  function createStripePaymentMethod(){

    stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      }).then(function(result){
        if (result.error) {
          changeLoadingState(false, '#stripe-submit-button');
          resultContainer.textContent = result.error.message;
        } else {
          stripeSucceeded(cardWrapper, cardSuccess);

          $.ajax({
            url: window.addPaymentMethodUrl,
            type: "POST",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                localStorage.getItem("bp-x-csrf-token")
              );
            },
            data: { stripe_card: result.paymentMethod.id },
            dataType: "script",
            success: () => {
              paymentSuccess();
            },
            error: () => {
              var errorContainer = document.getElementById('process-error');
              errorContainer.textContent = "We're sorry, something has gone wrong with our system. Your payment has succeeded, but an error occured updating your account. Please contact us before trying again.";
            }
          });
        }
      });
  }

  function createStripePaymentMethodWithJsonResponse() {
    stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      }).then(function(result){
        if (result.error) {
          changeLoadingState(false, '#stripe-submit-button');
          resultContainer.textContent = result.error.message;
        } else {
          $.ajax({
            url: window.addPaymentMethodUrl,
            type: "POST",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                localStorage.getItem("bp-x-csrf-token")
              );
            },
            data: { stripe_card: result.paymentMethod.id },
            dataType: "json",
            success: (data) => {
              if(data['success'] == true) {
                stripeSucceeded(cardWrapper, cardSuccess);
                paymentSuccess();
              } else {
                changeLoadingState(false, '#stripe-submit-button');
                resultContainer.textContent = data['message'];
              }
            },
            error: () => {
              var errorContainer = document.getElementById('process-error');
              errorContainer.textContent = "We're sorry, something has gone wrong with our system. Please contact us before trying again.";
            }
          });
        }
      });
  }

  function chargeStripePaymentIntent(){
    var name = $('#name').val();
    var email = $('#email').val();
    var phone = $('#phone').val();
    var clientSecret = $(cardButton).data('secret');

    if (window.updateMethod) {
      var updateMethod = window.updateMethod;
    } else {
      var updateMethod = 'PUT';
    }


    if ( $('#keep_card_on_file').is(":checked") ){
      var setup_future_usage = "off_session"
    } else {
      var setup_future_usage = null
    }

    var selectedPaymentMethod = 'new_card';

    if($('.js-payment-method-id').is('*')){
      var selectedPaymentMethod = $('.js-payment-method-id:checked').val();
    }

    if(selectedPaymentMethod == 'new_card') {
      var confirmCardPaymentArguments = {
        setup_future_usage: setup_future_usage,
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
            email: email,
            phone: phone
          }
        }
      }
    } else {
      var confirmCardPaymentArguments = {
        payment_method: selectedPaymentMethod,
        payment_method_options: {
          card: {
            cvc: verifyCardCvcElement
          }
        }
      }
    }


    stripe
      .confirmCardPayment(clientSecret, confirmCardPaymentArguments)
      .then(function (result) {
        if (result.error) {
          changeLoadingState(false, "#stripe-submit-button");
          resultContainer.textContent = result.error.message;
        } else {
          stripeSucceeded(cardWrapper, cardSuccess);

          $.ajax({
            url: window.updateInvoiceURL,
            type: updateMethod,
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                localStorage.getItem("bp-x-csrf-token")
              );
            },
            data: {
              stripe_payment_id: result.paymentIntent.id,
              payment_method: result.paymentIntent.payment_method,
              store_on_file: setup_future_usage,
              stripe_charged: result.paymentIntent.amount,
            },
            dataType: "script",
            success: () => {
              paymentSuccess();
            },
          });
        }
      });
  }

  function paymentSuccess(){
    if (window.redirectAfterSuccessURL) {
      window.location.replace(window.redirectAfterSuccessURL);
    }
  }
});

function applyDiscountCode() {
  var url = $('#js-apply-discount-code-btn').data('url');
  var invoiceId = $('#invoice-id').val();

  if($('#package-id').is('*')) {
    var packageId = $('#package-id').val()
  } else {
    var packageId = ''
  }

  var discountCode = $('#discount-code').val();
  var errorDiv = $('#discount-code-error');

  $.ajax({
    url: url,
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    data: {
        invoice_id: invoiceId,
        package_id: packageId,
        discount_code: discountCode
     },
    dataType: 'script',
    success: (response) => {
      errorDiv.fadeOut('fast');

      var json_response = JSON.parse(response);

      if (json_response.status == 200) {
        var pageURI = window.location.href;

        if (pageURI.indexOf('?') > -1){
          pageURI += '&discount_code='+json_response.discount_code;
        } else {
          pageURI += '?discount_code='+json_response.discount_code;
        }

        window.location.href = pageURI;
      } else {
        errorDiv.fadeIn('fast');
      }
    },
    error: () => {
      errorDiv.fadeIn("fast");
    },
  });
}

function getStripeSession() {
  $.ajax({
    url: $('#js-stripe-account-session').data('url'),
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'json',
    success: (response) => {
      if(response.status == 200) {
        window.StripeConnect ||= {};
        StripeConnect.onLoad = () => {
          StripeConnect.init({
            publishableKey: window.stripePublicKey,
            clientSecret: response.client_secret
          });
        };
      } else {
        console.log(response.message);
      }
    },
    error: () => {
      console.log("Failed to load Stripe Account Session");
    }
  });
}

function getStripeBalance() {
  $.ajax({
    url: $('#js-stripe-account-balance').data('url'),
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'json',
    success: (response) => {
      $('#js-stripe-account-balance').html(response);
    },
    error: () => {
      console.log("Failed to load Stripe Account Balance ");
      $('#js-stripe-account-balance').html('');
    }
  });
}
