$(document).on("turbolinks:load", function () {

  // Check if Intercom is loaded
  if (window.intercomSettings) {
    // to trigger events via the Intercom JS API, set window.intercomEvents as an array with the event names
    if (window.intercomEvents) {
      window.intercomEvents.forEach((event) => {
        Intercom('trackEvent', event);
      });
    }
  }

});
