import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    $('body').on('click', '.js-add-variant-row-btn', function() {
      if($('.js-variant-option-table-row:visible').length >= 2){
        $('.js-add-variant-row-btn').hide();
      } else {
        $('.js-add-variant-row-btn').show();
      }
    });

    $('body').on('click', '.js-variant-option-table-row .js-delete-field', function() {
      $('.js-add-variant-row-btn').show();
    });
  }

  updatePreviewWithTimeout(event) {
    var controller = this;

    setTimeout(() => {
      controller.updatePreview(event);
    }, 250);
  }

  updateDisplayValues(event) {
    var elem = $(event.target);
    var optionName = $(event.target).val();
    var optionValuesSelect = elem.closest('.js-variant-option-table-row').find('.js-option-values-select');

    optionValuesSelect.find('option').prop('style', 'display: none').prop('selected', false);
    optionValuesSelect.find('option[data-option-name="' + optionName + '"]').prop('style', '')

    $('.js-option-values-select').selectpicker("refresh");
  }

  updatePreview(event) {
    var formData = $(event.target).closest('form').serialize();

    fetch("/business/admin/products/preview_variants?" + formData)
      .then(response => response.text())
      .then(function(html){
        $('#js-variations-preview-container').html(html);
      });
  }

  openVaraintsModal(event){
    event.preventDefault();
    $('#variant-form-modal').modal();
    $('#variant-form-modal .modal-dialog-loading').show();
    $('#variant-form-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#variant-form-modal .modal-dialog-loading').hide();
        $('#variant-form-modal .modal-dialog-loaded').show();
        $('#variant-form-modal .modal-dialog-loaded').html(html);
      });
  }

}