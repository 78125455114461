import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    this.autogrow = this.autogrow.bind(this);
  }

  connect() {
    this.element.style.overflow = 'hidden';
    this.onResize = this.autogrow;

    var controller = this;
    setTimeout(function(){
      controller.autogrow()
    }, 100);

    this.element.addEventListener('input', this.autogrow)
    window.addEventListener('resize', this.onResize)
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize);
  }

  autogrow() {
    this.element.style.height = 'auto'; // Force re-print before calculating the scrollHeight value.

    var height = Math.max(this.element.scrollHeight, 50); // Always ensure a min height of 50px

    this.element.style.height = height + 'px';
  }
}