// Use this when you want a button to toggle show/hide on an element
//  For eg:
//    a.js-toggler-toggle-show-hide-target data-toggle-target=".class-or-div-you-want-to-show-hide"
onmount = require("onmount");

onmount(".js-toggler-toggle-show-hide-target", function () {
  var targetElem = $($(this).data('toggleTarget'));
  $(this).on("click", function () {
    targetElem.toggle();
  });
});

// Use this when you want a button to toggle show/hide on an element within the nearest scoped div. Useful for multiples.
//  For eg:
//    a.js-toggler-toggle-show-hide-target data-toggle-target=".class-or-div-you-want-to-show-hide" data-toggle-target-scope=".container-div"
onmount(".js-toggler-toggle-show-hide-target-with-scope", function () {
  $(this).on("click", function () {
    var targetElemScope = $(this).closest($(this).data('toggleTargetScope'));
    var targetElem = targetElemScope.find($(this).data('toggleTarget'));

    targetElem.toggle();
  });
});

// Use this when you want a button to show an element
//  For eg:
//    a.js-toggler-show-target data-show-target=".class-or-div-you-want-to-show"
onmount(".js-toggler-show-target", function () {
  var targetElem = $($(this).data('showTarget'));
  $(this).on("click", function () {
    targetElem.show();
  });
});

// Use this when you want a button to hide an element
//  For eg:
//    a.js-toggler-hide-target data-hide-target=".class-or-div-you-want-to-hide"
onmount(".js-toggler-hide-target", function () {
  var targetElem = $($(this).data('hideTarget'));
  $(this).on("click", function () {
    targetElem.hide();
  });
});

// Use this when you want a button to toggle on/off + set an array value on a hidden value.
onmount(".js-toggler-multi-btn", function () {
  $(this).on("click", function (event) {
    var shouldTurnOn = $(this).hasClass('btn-light');
    var targetElem = $($(this).data('inputTarget'));
    var itemVal = $(this).data('value');
    var targetElemVal = targetElem.val();

    if(targetElemVal == "") {
      targetElemVal = [];
    }else{
      targetElemVal = JSON.parse(targetElemVal);
    }

    if(shouldTurnOn) {
      $(this).removeClass('btn-light');
      $(this).addClass('btn-primary');
      targetElemVal.push(itemVal)
    } else {
      $(this).removeClass('btn-primary');
      $(this).addClass('btn-light');
      targetElemVal.splice(targetElemVal.indexOf(itemVal), 1);
    }

    targetElem.val(JSON.stringify(targetElemVal)).trigger('change');
    event.preventDefault();
  });
});

onmount();
