import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];
  static values = {
    initialState: String,
    url: String,
    bookingPetId: Number,
    checkedIn: Boolean,
    petName: String,
    bookingDate: String,
    bookingPrice: String
  };

  initialize() {
    var initial_state = JSON.parse(this.initialStateValue);

    this.url = initial_state.url;
    this.bookingPetId = initial_state.booking_pet_id;
    this.checkedIn = initial_state.checked_in;
    this.checkedOut = initial_state.checked_out;
    this.petName = initial_state.pet_name;
    this.bookingDate = initial_state.booking_date;
    this.bookingPrice = initial_state.booking_price;

    this.labelElem = $(this.element);
  }

  toggleAttendance(event){
    var csrfToken = localStorage.getItem("bp-x-csrf-token"),
        controllerContext = this;

    fetch(this.url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(controllerContext.getNextStatePayload()),
      credentials: "same-origin"
    }).then(function(response) {
      if(response.status == 200) {
        if(!controllerContext.checkedIn && !controllerContext.checkedOut) {
          controllerContext.markAsCheckedIn();
        } else if(controllerContext.checkedIn && !controllerContext.checkedOut) {
          controllerContext.markAsCheckedOut();
        } else {
          controllerContext.resetCheckInAndOut();
        }
      } else if (response.status == 400 && Object.fromEntries(response.headers)['content-type'].includes("json")) {
        response.json().then((json) => { controllerContext.errorMessageAlertWithText(json.error); } );
      } else {
        controllerContext.errorMessageAlert();
      }
    }).catch(function(error) {
      controllerContext.errorMessageAlert();
    });
  }

  getNextStatePayload() {
    if(!this.checkedIn && !this.checkedOut){
      return { checked_in: true, checked_out: false }
    } else if(this.checkedIn && !this.checkedOut) {
      return { checked_in: true, checked_out: true }
    } else {
      return { checked_in: false, checked_out: false }
    }
  }

  checkedInOrOut(){
    if(this.checkedIn && !this.checkedOut) {
      return 'checked in';
    } else if(this.checkedIn && this.checkedOut) {
      return 'checked out';
    } else {
      return 'reset for';
    }
  }

  markAsCheckedIn() {
    this.checkedIn = true;

    this.labelElem.addClass("btn-success");
    this.labelElem.removeClass("btn-dark");
    this.labelElem.removeClass("btn-light");
    this.labelElem.find(".js-attendance-icon.checked-in").removeClass("d-none");
    this.labelElem.find(".js-attendance-icon.checked-out, .js-attendance-icon.not-checked-in-or-out").addClass("d-none");

    this.successMessageAlert();
    this.handleAttendanceCheckinToggleEvent();
  }

  markAsCheckedOut() {
    this.checkedIn = true;
    this.checkedOut = true;

    this.labelElem.addClass("btn-dark");
    this.labelElem.removeClass("btn-light");
    this.labelElem.removeClass("btn-success");
    this.labelElem.find(".js-attendance-icon.checked-out").removeClass("d-none");
    this.labelElem.find(".js-attendance-icon.checked-in, .js-attendance-icon.not-checked-in-or-out").addClass("d-none");

    this.successMessageAlert();
    this.handleAttendanceCheckinToggleEvent();
  }

  resetCheckInAndOut() {
    this.checkedIn = false;
    this.checkedOut = false;

    this.labelElem.addClass("btn-light");
    this.labelElem.removeClass("btn-success");
    this.labelElem.removeClass("btn-dark");
    this.labelElem.find(".js-attendance-icon.not-checked-in-or-out").removeClass("d-none");
    this.labelElem.find(".js-attendance-icon.checked-in, .js-attendance-icon.checked-out").addClass("d-none");

    this.successMessageAlert();
    this.handleAttendanceCheckinToggleEvent();
  }

  successMessageAlert() {
    window.notyf.success(
      this.petName +
        " is successfully " +
        this.checkedInOrOut() +
        " for " +
        this.bookingDate
    );
  }

  errorMessageAlert() {
    window.notyf.error(
      "ERROR: " +
        this.petName +
        " failed to be marked as " +
        this.checkedInOrOut() +
        " for " +
        this.bookingDate
    );
  }

  errorMessageAlertWithText(text) {
    console.log("TEXT!", text);
    window.notyf.error(
      "ERROR: " +
        this.petName +
        " failed to be marked as " +
        this.checkedInOrOut() +
        " for " +
        this.bookingDate +
        "<br><br>" +
        text
    );
  }

  handleAttendanceCheckinToggleEvent() {
    const complete_booking_controller = document.querySelector(
      'div[data-controller="complete-booking"]'
    );

    if (complete_booking_controller) {
      const event = new CustomEvent("attendanceCheckinToggle", {
        detail: { checked_in: this.checkedIn, checked_in: this.checkedOut, bookingPetId: this.bookingPetId, bookingPrice: this.bookingPrice },
      });
      complete_booking_controller.dispatchEvent(event);
    }
  }
}
