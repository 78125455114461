$(document).on("turbolinks:load", function () {
  bindSearches();
  bindOnChange();
});

function bindSearches() {
  var exclude_client_id =
    $("#js-merge-client-or-pet-select").data("excludeClientId") || "";

  var exclude_pet_id =
    $("#js-merge-client-or-pet-select").data("excludePetId") || "";

  $("#js-merge-client-or-pet-select")
    .selectpicker({
      actionsBox: true,
      liveSearch: true,
      liveSearchPlaceholder: "Select Customer",
    })
    .ajaxSelectPicker({
      locale: { currentlySelected: "" },
      ajax: {
        url: $("#js-merge-client-or-pet-select").data("url"),
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: function () {
          var params = {
            q: "{{{q}}}",
            exclude_client_id: exclude_client_id.toString(),
            exclude_pet_id: exclude_pet_id.toString(),
          };
          // This is how to add more data to params if you so desire. In the
          // example the GroupID parameter is added so the GET request will be similar to
          // ?q=<q>&GroupID=<assigned-value>
          //
          //if (gModel.selectedGroup().hasOwnProperty("ContactGroupID")) {
          //  params.GroupID = gModel.selectedGroup().ContactGroupID;
          //}
          return params;
        },
      },
      clearOnEmpty: true,
      preserveSelectedPosition: "before",
      emptyRequest: true,
      cache: false,
      preserveSelected: true,
    });
}

function bindOnChange() {
  $("#js-merge-client-or-pet-select").on("change", function () {
    data = $("#js-merge-client-or-pet-select-form").serializeArray();

    $.ajax({
      url: "/business/merge_client_or_pets/load_form",
      type: "GET",
      data: data,
      dataType: "script",
    });
  });
}
