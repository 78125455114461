// Docs: https://github.com/caroso1222/notyf
import { Notyf } from "notyf";

document.addEventListener("DOMContentLoaded", function (event) {
  const notyf = new Notyf({
    duration: 5000,
    position: {
      x: "right",
      y: "top",
    },
  });
  window.notyf = notyf;
});

document.addEventListener("turbolinks:load", function (event) {
  const notyf = new Notyf({
    duration: 5000,
    position: {
      x: "right",
      y: "top",
    },
  });

  window.notyf = notyf;
});
