import { Controller } from "stimulus";

export default class extends Controller {
  importTypeChanged(event) {
  	var selcetedImportType = $(event.target).val();

  	if(selcetedImportType == 'product_list'){
      $('.js-product-list').show();
      $('.js-customer-list').hide();
  	} else if(selcetedImportType == 'client_list') {
      $('.js-product-list').hide();
      $('.js-customer-list').show();
  	}
  }
}