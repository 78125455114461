$(document).on("turbolinks:load", function () {
  if ($('#js-how-did-you-hear-about-us-select').is('*')) {

    $('#js-how-did-you-hear-about-us-select').on('change', (event) => {
      var selectedOption = $('#js-how-did-you-hear-about-us-select option:selected').val();

      if(selectedOption === 'Other'){
        $('#js-how-did-you-hear-about-us-other-input').removeClass('d-none');
      } else {
        $('#js-how-did-you-hear-about-us-other-input').addClass('d-none');
      }
    });

  }
});
