import { Controller } from "stimulus";
import { Decimal } from "decimal.js";

export default class extends Controller {
  static targets = ["input", "output"];

  connect(obj) {
    this.inputTargets.forEach((item) => {
      if (!item.value) {
        item.value = item.dataset.expectedHours;
      }

      item.placeholder = item.dataset.expectedHours;
    });
    this.inputChanged(null);
  }

  // event: Event
  inputChanged(event) {
    let sum = new Decimal("0.0");
    this.inputTargets.forEach((item) => {
      if (Number.isFinite(item.valueAsNumber)) {
        item.value = item.valueAsNumber.toFixed(2);
        if (item.valueAsNumber != item.dataset.expectedHours) {
          item.classList.add("bg-warning");
          $(item)
            .parent()
            .find(".js-reset-time-entry-btn")
            .removeClass("d-none");
        } else {
          item.classList.remove("bg-warning");
          $(item).parent().find(".js-reset-time-entry-btn").addClass("d-none");
        }
        let newValue = new Decimal(item.valueAsNumber || 0);
        sum = sum.plus(newValue);
      }
    });

    this.outputTarget.innerHTML = sum.toFixed(2);
  }

  clearClick(event) {
    var elem = event.target.closest(".input-group").querySelector("input");
    console.log(elem);

    // Why are we doing this? Because we want to reset the value to null in the
    // db so that `total_hours` is used when calculating hours
    elem.value = null;
    elem.closest("form").dispatchEvent(new Event("change")); // this is to trigger js-remote-form form submit

    // Now we set it to the correct value to the user
    elem.value = elem.dataset.expectedHours;
    this.inputChanged(null);
  }
}
