import { isControlKey } from "./is_control_key";

$(document).on("turbolinks:load", function () {
  if ($(".js-remote-form").is("*")) {
    window.setupJSRemoteForm();
  }
});

onmount(".js-remote-form-only-change", () => {
  window.setupJSRemoteFormOnlyChange();
});

onmount(".js-remote-form-script", () => {
  window.setupJSRemoteFormScript();
});

window.jsRemoteForm = function jsRemoteForm(elem) {
  $.ajax({
    url: $(elem).attr("action"),
    type: $(elem).attr("method"),
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    data: $(elem).serialize(),
    dataType: "json",
    success: (response) => {
      var message = response.message || "Saved!";
      window.notyf.success(message);
    },
    error: (response) => {
      if (response.status == "500") {
        console.log("500");
        console.log("response", response);
        message = "Sorry, something went wrong. No data was changed.";
      } else {
        var message =
          (response.responseJSON && response.responseJSON.message) ||
          "Sorry, something went wrong. No data was changed.";
      }
      window.notyf.error(message);
    },
  });
};

window.setupJSRemoteForm = function () {
  var forms = document.getElementsByClassName("js-remote-form");

  forms.forEach((form) => {
    if (!$(form).hasClass("js-form-loaded")) {
      form.addEventListener(
        "change",
        function () {
          jsRemoteForm(this);
        },
        false
      );

      form.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
        },
        false
      );

      form.addEventListener(
        "keyup",
        function (event) {
          if (!isControlKey(event)) {
            console.log("KEYUP!");
            jsRemoteForm(this);
          }
        },
        false
      );

      $(form).addClass("js-form-loaded");
    }
  });
};

window.jsRemoteFormScript = function jsRemoteFormScript(elem) {
  $.ajax({
    url: $(elem).attr("action"),
    type: $(elem).attr("method"),
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    data: $(elem).serialize(),
    dataType: "script",
    success: (_response) => {},
    error: (response) => {
      var message = "Sorry, something went wrong. No data was changed";
      console.log("message", message);
      window.notyf.error(message);
    },
  });
};

window.setupJSRemoteFormOnlyChange = function () {
  var forms = document.getElementsByClassName("js-remote-form-only-change");

  forms.forEach((form) => {
    if (!$(form).hasClass("js-form-loaded-script")) {
      form.addEventListener(
        "change",
        function () {
          jsRemoteForm(this);
        },
        false
      );

      form.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
        },
        false
      );

      $(form).addClass("js-form-loaded-script");
    }
  });
};

window.setupJSRemoteFormScript = function () {
  var forms = document.getElementsByClassName("js-remote-form-script");

  forms.forEach((form) => {
    if (!$(form).hasClass("js-form-loaded-script")) {
      form.addEventListener(
        "keyup",
        function () {
          jsRemoteFormScript(this);
        },
        false
      );

      form.addEventListener(
        "submit",
        function () {
          jsRemoteFormScript(this);
        },
        false
      );

      $(form).addClass("js-form-loaded-script");
    }
  });
};
