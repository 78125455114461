import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect(obj) {
    this.element["stimulus_controller"] = this;
    this.inputChanged(null);
  }

  // event: Event
  inputChanged(event) {
    this.inputTargets.forEach((item) => {
      if (item.value != (item.dataset.dirtyValue || "")) {
        item.classList.add("bg-warning");
      } else {
        item.classList.remove("bg-warning");
      }
    });
  }
}
