onmount = require("onmount");


$(document).on("turbolinks:load", function () {
  onmount("#client-discount-code-form", function () {
    bindSeriesOrServiceSelectChange();
    $('.js-add-service-select').on('after-load', bindSeriesOrServiceSelectChange);
  });

  onmount();
});

function bindSeriesOrServiceSelectChange() {
  $('.js-select-package-service-with-duration-toggle').on('change', function(){
    var durationSettingsContainer = $(this).parents('.js-delete-target').find('.add-duration-settings');

    if($(this).find(':selected').data('series') || $(this).find(':selected').data('boarding') || $(this).find(':selected').data('multiClient')) {
      var durationRequirement = durationSettingsContainer.find('.js-toggler-toggle-show-hide-target-with-scope')
      if(durationRequirement.is(':checked')) {
        durationRequirement.click();
      }
      durationSettingsContainer.hide();
    } else {
      durationSettingsContainer.show();
    }
  });

  $('.js-select-package-service').on('change', function(){
    var id = $(this).find(':selected').val();
    var addon_id = $(this).parents('.js-delete-target').find("input[name*='addon_id']");
    var service_id = $(this).parents('.js-delete-target').find("input[name*='service_id']");
    var series_id = $(this).parents('.js-delete-target').find("input[name*='series_id']");
    var price_input = $(this).parents('.js-delete-target').find("input[name*='price']")

    if ( $(this).find(':selected').data('series') ) {
      addon_id.val('');
      service_id.val('');
      series_id.val(id.replace('[series]', ''));
    } else if($(this).find(':selected').data('addon')) {
      addon_id.val(id.replace('[addon]', ''));
      service_id.val('');
      series_id.val('');
    } else {
      addon_id.val('');
      service_id.val(id);
      series_id.val('');
    }

    $(this).parents('.js-delete-target').find("input[name*='quantity']").val(1);
    price_input.val($(this).find(':selected').data('price'));
  });
}