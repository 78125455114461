$(document).on("turbolinks:load", function () {
  $(".js-online-payments-option").on("click", function () {
    var clickedOption = $(this);
    var form = clickedOption.parents("form");

    form.find("input:checked").each(function () {
      if ($(this).attr("id") != clickedOption.attr("id")) {
        $(this).prop("checked", false);
        if ($(this).hasClass("js-toggler-toggle-show-hide-target")) {
          $($(this).data("toggleTarget")).toggle();
        }
      }
    });
  });

  $('.js-show-schedule-start-date').on('click', function(e){
    e.preventDefault();

    $(this).parent().find('.js-start-date-container').removeClass('d-none');
    $(this).hide();
  });
});
