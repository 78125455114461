$(document).on("turbolinks:load", function () {
  $(".js-show-hide-password a").on('click', function(event) {
      event.preventDefault();

      var parentElem = $(this).closest('.js-show-hide-password');
      var passwordInput = parentElem.find('input');
      var passwordIcon = parentElem.find('i');

      if(passwordInput.attr("type") == "text"){
        passwordInput.attr('type', 'password');
        passwordIcon.addClass( "fa-eye-slash" );
        passwordIcon.removeClass( "fa-eye" );
      }else if(passwordInput.attr("type") == "password"){
        passwordInput.attr('type', 'text');
        passwordIcon.removeClass( "fa-eye-slash" );
        passwordIcon.addClass( "fa-eye" );
      }
  });
});