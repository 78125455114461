import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var select = $(this.element).find('select.js-product-typeahead');

    select.selectpicker({ liveSearch: true, liveSearchPlaceholder: "Add Product" })
      .ajaxSelectPicker({
        ajax: {
          url: "/business/search/product_grouped_by_inventory",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              localStorage.getItem("bp-x-csrf-token")
            );
          },
          data: function () {
            var params = {
              q: "{{{q}}}",
            };

            return params;
          },
        },
        clearOnEmpty: false,
        emptyRequest: true,
        cache: false,
        preserveSelected: false,
      });

    select.on('change', function(){
      var selectedOption = $(this).find(":selected");
      var container = $(this).closest('.js-product-admin-row');

      var productId = selectedOption.data('product_id');
      var variantId = selectedOption.data('variant_id');
      var inventoryId = selectedOption.data('inventory_id');

      container.find('.js-product-id').val(productId);
      container.find('.js-variant-id').val(variantId);
      container.find('.js-inventory-id').val(inventoryId);

    });
  }

}