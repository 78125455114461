import { Controller } from "stimulus";


export default class extends Controller {

  connect() {
    this.setupWeeklyDatepicker();
  }

  setupWeeklyDatepicker() {
    var mySchedule = this.element.dataset.mySchedule === 'true';
    var locationId = this.element.dataset.locationId;
    var userId = this.element.dataset.userId;

    $("#schedule-builder .btn-date-range").flatpickr({
      onChange: function(selectedDates, dateStr, instance) {
        window.location = window.location.pathname + "?date=" + dateStr + "&my_schedule=" + mySchedule + "&location_id=" + locationId + '&user_id=' + userId;
      },
      disableMobile: "true"
    });
  }

  checkAssociatedServicesUserAssignment(event) {
    var accessLevel  = $(event.target).find("option:selected").data('accessLevel');

    if(accessLevel == "lite") {
      $('.js-lite-user').show();
      $('.js-non-lite-user').hide();
      $('.custom-control.custom-switch .custom-control-input:checked').click();
    } else {
      $('.js-lite-user').hide();
      $('.js-non-lite-user').show();
    }
  }

  formLocationChanged(event) {
    var locationIdSelected = $(event.target).val();
    $('.service-input-container input[type=checkbox]:checked').click(); // Deselect from the other location if selected
    $('.service-input-container').hide();
    $('.service-input-container[data-location-id=' + locationIdSelected + ']').show();
  }

  openActionsModal(event){
    event.preventDefault();
    $('#schedule-builder-services-modal').modal();
    $('#schedule-builder-services-modal .modal-dialog-loading').show();
    $('#schedule-builder-services-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#schedule-builder-services-modal .modal-dialog-loading').hide();
        $('#schedule-builder-services-modal .modal-dialog-loaded').show();
        $('#schedule-builder-services-modal .modal-dialog-loaded').html(html);
        $('[data-toggle="tooltip"]').tooltip();
        window.loadFlatpickr();
      });
  }

}