// http://stackoverflow.com/a/9795091
$.fn.wrapInTag = function (opts) {
  // http://stackoverflow.com/a/1646618
  function getText(obj) {
    return obj.textContent ? obj.textContent : obj.innerText;
  }

  var tag = opts.tag || "strong",
    words = opts.words || [],
    regex = RegExp(words.join("|"), "gi"),
    replacement = "<" + tag + ">$&</" + tag + ">";

  // http://stackoverflow.com/a/298758
  $(this)
    .contents()
    .each(function () {
      if (this.nodeType === 3) {
        //Node.TEXT_NODE
        // http://stackoverflow.com/a/7698745
        $(this).replaceWith(getText(this).replace(regex, replacement));
      } else if (!opts.ignoreChildNodes) {
        $(this).wrapInTag(opts);
      }
    });
};

onmount("[data-js-highlight-text]", function () {
  var highlight_phrase = $(this).data("js-highlight-text");
  if (!!!highlight_phrase) return;

  var color = $(this).data("js-highlight-text-color") || "#009fff";

  $(this).wrapInTag({
    words: [highlight_phrase],
    tag: 'span style="color: ' + color + ';"',
  });
});
