// See https://developer.snapappointments.com/bootstrap-select/options/#sanitizer
var myDefaultWhiteList = $.fn.selectpicker.Constructor.DEFAULTS.whiteList;

// // To allow table elements
// myDefaultWhiteList.table = [];
//
// // To allow td elements and data-option attributes on td elements
// myDefaultWhiteList.td = ['data-option'];
//
// // You can push your custom regex to validate your attributes.
// // Be careful about your regular expressions being too lax
// var myCustomRegex = /^data-my-app-[\w-]+/;
// myDefaultWhiteList['*'].push(myCustomRegex);
myDefaultWhiteList["*"].push("data-js-highlight-text");

require("onmount");

var onmount = require("onmount");

onmount("#reset-search-input", function (event) {
  this.addEventListener("click", (item) => {
    document.querySelector("#search-input").value = "";
    document.querySelector("#search-input").focus();
    document.querySelector("#reset-search-input").remove();
  });
});
