addEventListener("direct-upload:error", (event) => {
  event.preventDefault();

  window.notyf.error(
    "Sorry, something went with your upload. Your file, image or video has not been saved."
  );

  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  console.log("element found in direct-upload:error", element);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});
