$(document).on("turbolinks:load", function () {
  if ($('#js-package-term-text').is('*')) {
    var freq_select = $('#js-frequency-select');

    replaceText();
    freq_select.on('change', replaceText);

    addBindings();
    $('.js-add-service-select').on('after-load', addBindings);
  }

});

function addBindings() {
  bind_select_change();
  bind_qty_change();
  bind_delete();
}

function bind_delete() {
  $('.js-delete-field').on('click', function() {
    // wait for delete function to run

    setTimeout( function(){
      update_pricing();
    }, 500)

  });
}

function bind_qty_change() {
  $('.quantity input').on('change', function(){
    var price = $(this).parents('.js-delete-target').find("select").find(':selected').data('price');
    var price_input = $(this).parents('.js-delete-target').find("input[name*='price']")
    if ( price ) {
      price_input.val(($(this).val() * price).toFixed(2));
    } else {
      price_input.val(0.00);
    }
    update_pricing();
  });

  $('.unit-price').on('change keyup', update_pricing);
}

function bind_select_change() {
  $('.js-select-package-service').on('change', function(){
    var id = $(this).find(':selected').val();
    var service_id = $(this).parents('.js-delete-target').find("input[name*='service_id']");
    var series_id = $(this).parents('.js-delete-target').find("input[name*='series_id']");
    var price_input = $(this).parents('.js-delete-target').find("input[name*='price']")

    if ( $(this).find(':selected').data('series') ) {
      service_id.val('');
      series_id.val(id.replace('[series]', ''));
    } else {
      service_id.val(id);
      series_id.val('');
    }

    $(this).parents('.js-delete-target').find("input[name*='quantity']").val(1);
    price_input.val($(this).find(':selected').data('price'));
    update_pricing();
  });
}

function update_pricing() {
  var package_total = $('#js-package-total');
  var package_total_text = $('#js-package-total-text');
  var currency_symbol = package_total_text.data('currency');
  var sum_price = 0.0;

  $('.unit-price').each( function (){
    if ($(this).parents('.js-delete-target').find('.destroy-check').val() != 'true') {
      sum_price += parseFloat($(this).val());
    }
  });

  package_total.val(sum_price.toFixed(2));
  package_total_text.text(currency_symbol + sum_price.toFixed(2));
}

function replaceText() {
  var freq_select = $('#js-frequency-select');
  var term_text = $('#js-package-term-text');
  var text = '';

  switch ( freq_select.val() ) {
    case 'does-not-expire':
      $('#js-term-row').slideUp('fast');
      break;
    case 'weekly':
      text = 'Weeks';
      break;
    case 'monthly':
      text = 'Months';
      break;
    case 'quarterly':
      text = 'Quarters';
      break;
    case 'semi-annual':
      text = 'Halves';
      break;
    case 'yearly':
      text = 'Years';
  }

  if ( text == '') {
    $('#js-term-row').slideUp('fast');
  } else {
    $('#js-term-row').slideDown('fast');
  }
  term_text.text(text);
}
