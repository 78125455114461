import Calendar, { MonthGuide } from "tui-calendar"; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";

var moment = require("moment-timezone");

$(document).on("turbolinks:load", function () {
  if ($('.js-resource-calendar').is('*')) {
    $('.js-resource-calendar').each(function(){
      createCalendar(this);
    })

    var date_select = flatpickr(".js-resource-flatpickr", {
      autoclose: true,
      monthSelectorType: "static",
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d"
    });

    $('.js-resource-calendar-today').on('click', function(){
      date_select.setDate($(this).data('today'), true)
    })

    $('.js-resource-calendar-backward').on('click', function(){
      var date = $('#resource_date').val();
      var move_to_date = moment(date, 'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD')
      date_select.setDate(move_to_date, true)
    })

    $('.js-resource-calendar-forward').on('click', function(){
      var date = $('#resource_date').val();
      var move_to_date = moment(date, 'YYYY-MM-DD').add(1,'days').format('YYYY-MM-DD')
      date_select.setDate(move_to_date, true)
    })
  }
});

function createCalendar(element) {
  var location_resource_id = $(element).data('room-id');
  var user_id = $(element).data('user-id');

  var tzIdentifier = $(element).data('calendar-tzinfo-identifier');

  var calendar = new Calendar(element, {
    defaultView: 'day',
    // week: {
    //   startDayOfWeek: 1,
    //   hourStart: start_hour,
    //   hourEnd: end_hour,
    // },
    taskView: false,
    isReadOnly: false,
    useDetailPopup: false,
    template: {
      time: function (schedule) {
        return getTimeTemplate(schedule, false);
      },
    },
    timezone: {
      zones: [
        {
          timezoneName: tzIdentifier,
          displayLabel: tzIdentifier,
          tooltip: tzIdentifier
        },
      ],
      offsetCalculator: function (timezoneName, timestamp) {
        // matches 'getTimezoneOffset()' of Date API
        // e.g. +09:00 => -540, -04:00 => 240
        return moment.tz.zone(timezoneName).utcOffset(timestamp);
      },
    },
  });

  var date = $('#resource_date').val();

  refreshCalendar(calendar, date, location_resource_id, user_id);
  setCalendarBindings(calendar);

  $('#resource_date').on('change', function(){
    var date = $('#resource_date').val();
    refreshCalendar(calendar, date, location_resource_id, user_id);
  })
}

function refreshCalendar(calendar, date, location_resource_id, user_id) {
  if(user_id === undefined){
    var user_id = '';
  }

  if(location_resource_id === undefined){
    var location_resource_id = '';
  }

  getData({
    start: date,
    end: moment(date).add(1,'days'),
    location_resource_id: location_resource_id,
    user_id: user_id
  }).then((data) => {
    calendar.setDate(date);
    calendar.clear();
    calendar.createSchedules(data);
  });
};

async function getData(data = {}) {
  var url = '/business/calendars/calendar' + "?" + new URLSearchParams(data);

  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function setCalendarBindings(calendar) {
  var lastClickSchedule = null;
  calendar.on({
    clickSchedule: function (event) {
      // console.log("clickSchedule event", event);
      var schedule = event.schedule;

      if (!schedule.raw || !schedule.raw.visitable_url) {
        return;
      }

      // focus the schedule
      if (lastClickSchedule) {
        calendar.updateSchedule(
          lastClickSchedule.id,
          lastClickSchedule.calendarId,
          {
            color: "#fff",
            isFocused: false,
          }
        );
      }
      calendar.updateSchedule(schedule.id, schedule.calendarId, {
        color: "#cb00ff",
        isFocused: true,
      });

      // Set our lastClickSchedule to the new one
      lastClickSchedule = schedule;

      // open detail view
      window.location.href = schedule.raw.visitable_url;
    },
    beforeUpdateSchedule: function (event) {
      // console.log("beforeUpdateSchedule event", event);
    },
    beforeCreateSchedule: function (event) {
      var start_at_date_param = moment(event.start.toDate())
        .toISOString()
        .replace(/T.*/g, "");

      var diff = Math.abs(event.end.toDate() - event.start.toDate());
      var start_at_time = moment(event.start.toDate()).format("h:mm A");

      // you can change the text (maybe) by setting .title below:
      // event.guide.guideElement.title = "New booking";

      if (event.guide.guideElements) {
        setTimeout(() => {
          event.guide.clear();
        }, 1000);

        // we're in month view - let's drill into the selected date
        calendar.setDate(event.start);
        setView("day");
      } else if (event.guide.guideElement) {
        event.guide.guideElement.dataset.content = `<a href='/business/bookings/new?start_at_date=${start_at_date_param}&start_at_time=${start_at_time}' class='btn btn-tertiary'><i class="fa fa-plus-circle"></i>&nbsp;Add booking</a>`;
        var popover_guide = event.guide.guideElement;
        $(event.guide.guideElement).popover({
          placement: "left",
          html: true,
        });
        $(event.guide.guideElement).popover("show");
      } else {
        // TODO: send HB/LogRocket notification
        console.log(
          "We're not able to spawn a 'Add Booking' button or go to the day in question"
        );
      }
    },
  });
}

/**
 * Get time template for time and all-day
 * @param {Schedule} schedule - schedule
 * @param {boolean} isAllDay - isAllDay or hasMultiDates
 * @returns {string}
 */

function getTimeTemplate(schedule, isAllDay) {
  var html = [];
  var start = moment(schedule.start.toUTCString());
  if (!isAllDay) {
    html.push("<strong>" + start.format("h:mma") + "</strong> ");
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(" Private");
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }

    if (schedule.raw.bookingType == "group") {
      html.push('<i class="fas fa-users mx-1"></i>');
    } else if (schedule.raw.bookingType == "series") {
      html.push('<i class="fas fa-chevron-double-right mx-1"></i>');
    } else {
      html.push('<i class="fas fa-user mx-1"></i>');
    }

    html.push(" " + schedule.title);
  }

  return html.join("");
}