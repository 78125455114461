import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ["input"];

  connect() {
    this.bindEvents();
  }

  bindEvents() {}

  flip() {
    let body = document.querySelector("body");
    if (body.classList.contains("bp-with-pre-warning")) {
      body.classList.remove("bp-with-pre-warning");
    } else {
      body.classList.add("bp-with-pre-warning");
    }
  }
}
