$(document).on("turbolinks:load ready", function () {
  if ($(".js-select-dynamic-search").is("*")) {
    document
      .getElementsByClassName("js-select-dynamic-search")
      .forEach(createSelectpickerSearch);
  }
});

function createSelectpickerSearch(element) {
  var search_url = $(element).data("search-url");
  var new_object_option = $(element).data("new");

  $(element)
    .selectpicker({
      actionsBox: true,
      liveSearch: true,
    })
    .ajaxSelectPicker({
      ajax: {
        url: search_url + "?new_object=" + new_object_option,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: function () {
          var params = {
            q: "{{{q}}}",
          };

          return params;
        },
      },
      clearOnEmpty: false,
      emptyRequest: true,
      cache: false,
      preserveSelected: true,
    });
}

onmount(".js-selectpicker-add-more", function () {
  document
    .getElementsByClassName("js-selectpicker-add-more")
    .forEach(createAddMoreSelectpicker);
})

function createAddMoreSelectpicker(element) {
  if($(element).hasClass('js-has-init')){
    return;
  }

  $(element).addClass('js-has-init')

  $(element)
    .selectpicker({
      actionsBox: false,
      liveSearch: true,
      noneResultsText: "<span class='btn btn-outline-primary btn-block js-selectpicker-new-option'>+ Add <span class='new-option'>{0}</span></span>"
    })

  $('body').on('click', '.js-selectpicker-new-option', function (event) {
    var newOptionElem = $(event.target);

    if(!newOptionElem.hasClass('new-option')){
      var newOptionElem = $(event.target).find('.new-option');
    }

    window.selectPickerLastNewOptionName = newOptionElem.html().replace('"', '').replace('"', '');
  });

  $(element).on('hidden.bs.select', function(){
    if(window.selectPickerLastNewOptionName !== undefined && window.selectPickerLastNewOptionName !== null && window.selectPickerLastNewOptionName.length > 0) {
      $(this).append($('<option>', {
          value: window.selectPickerLastNewOptionName,
          text: window.selectPickerLastNewOptionName,
          selected: 'selected'
        }));

      $(this)[0].dispatchEvent(new Event("change"));

      $('.js-selectpicker-add-more').selectpicker('refresh');

      window.selectPickerLastNewOptionName = null;
    }
  });


}