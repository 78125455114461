$(document).on("turbolinks:load", function () {

  // populate the google analytics client_id into the field
  if ($('input.js-get-google-client-id').is('*') && window.environment != 'test') {
    var measurementId = window.measurement_id

    gtag('get', measurementId, 'client_id', (client_id) => {
      $('input.js-get-google-client-id').each( function(i, input) {
        $(input).val(client_id);
      });
    });

  }

});
