import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.setupCheckForBatchSaveWarning();
  }

  setupCheckForBatchSaveWarning() {
    var controller = this;

    $('.js-adjust-inventory-form').each(function(){
      $(this).data("originalState", $(this).serialize());
      $(this).data("isDirty", false);
    });

    $('.js-adjust-inventory-form input').each(function(){
      $(this).on('change', function(){
        controller.checkForBatchSaveWarning();
      });
    });
  }

  checkForBatchSaveWarning() {
    var shouldDisplayWarningCount = 0;

    $('.js-adjust-inventory-form').each(function(){
      var originalState = $(this).data("originalState");

      if(originalState != $(this).serialize()) {
        shouldDisplayWarningCount++;
        $(this).data("isDirty", true);

        $('.js-adjust-inventory-form .js-dirtify')
      } else {
        $(this).data("isDirty", false);
      }
    });

    if(shouldDisplayWarningCount > 0) {
      var unsavedMessage = "You have " + shouldDisplayWarningCount + " unsaved change" + (shouldDisplayWarningCount > 1 ? "s" : "") + ".";

      $('#js-batch-save-changes').fadeIn();
      $('#js-batch-save-changes .js-unsaved-message').html(unsavedMessage);
    } else {
      $('#js-batch-save-changes').hide();
    }
  }

  saveAllDirtyForms(event) {
    event.preventDefault();
    event.stopPropagation();

    $('#js-batch-save-changes .js-btn-submit').prop('disabled', 'disabled');
    $('#js-batch-save-changes .js-btn-submit').html('Saving Changes');

    $('.js-adjust-inventory-form').each(function(){
      var isDirty = $(this).data("isDirty");
      var form = $(this);
      var url = form.attr('action');
      var data = form.serialize();

      if(isDirty) {
        $.ajax({
          type: "POST",
          url: url,
          data: data,
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"))
          },
          success: function(data) {
            window.notyf.success({
              message: "Succesfully saved change",
              duration: 4500
            });
          },
          error: function(data) {
            console.log("Failed saveAllDirtyForms", data);
            window.notyf.error({
              message: "Failed to saved change",
              duration: 4500
            });
          },
        });

        var originalQuantity = $(this).find('.js-quantity-input').val();

        $(this).find('.js-quantity-input').data('originalquantity', originalQuantity);
        $(this).find('.js-increase-by').data('originalquantity', originalQuantity);
        $(this).find('.js-increase-by').val('0');

        $(this).data("isDirty", false);
        $(this).data("originalState", form.serialize());
      }
    });

    setTimeout(function () {
      $('.js-quantity-input').removeClass('bg-light-blue');
      $('.js-increase-by').removeClass('bg-light-blue');
    }, 350);

    $('.dropdown-menu:visible').trigger("click");
    $('#js-batch-save-changes').fadeOut();
    $('#js-batch-save-changes .js-btn-submit').prop('disabled', false);
    $('#js-batch-save-changes .js-btn-submit').html('Save Changes');
  }

  setQty(event) {
    var elem = $(event.target);
    var parentElem = $(elem.data('quantity'));

    var increaseBy = elem.val();
    var originalQty = elem.data('originalquantity');
    var newQty =  parseInt(increaseBy) + parseInt(originalQty);

    parentElem.val(newQty);
    parentElem.trigger('change');

    if(increaseBy == 0){
      elem.removeClass('bg-light-blue');
      parentElem.removeClass('bg-light-blue');
    } else {
      elem.addClass('bg-light-blue');
      parentElem.addClass('bg-light-blue');
    }
  }

  setIncreaseBy(event) {
    var elem = $(event.target);
    var increaseByElem = $(elem.data('increaseby'));
    var qty = elem.val();
    var originalQty = elem.data('originalquantity');

    var difference = parseInt(qty) - parseInt(originalQty);

    increaseByElem.val(difference);

    elem.addClass('bg-light-blue');
    increaseByElem.addClass('bg-light-blue');

    if(elem.val() != originalQty){
      elem.addClass('bg-light-blue');
      increaseByElem.addClass('bg-light-blue');
    } else {
      elem.removeClass('bg-light-blue');
      increaseByElem.removeClass('bg-light-blue');
    }
  }

  openInventoryHistoryModal(event){
    event.preventDefault();
    $('#adjust-inventory-form-modal').modal();
    $('#adjust-inventory-form-modal .modal-dialog-loading').show();
    $('#adjust-inventory-form-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#adjust-inventory-form-modal .modal-dialog-loading').hide();
        $('#adjust-inventory-form-modal .modal-dialog-loaded').show();
        $('#adjust-inventory-form-modal .modal-dialog-loaded').html(html);

        $('[data-toggle="tooltip"]').tooltip();
      });
  }

}