$(document).on("turbolinks:load", function () {
  if ($('.js-quick-checkin-btn').is('*') || $('#quick-checkin-modal-btn').is('*')) {
    $('body').on('click', '.js-quick-checkin-btn', function (event) {
      quickBookingToggleIn(this);

      return false;
    });

    $('body').on('click', '#quick-checkin-modal-btn', function (event) {
      if ($('#quick_checkin_modal').hasClass('is-loading')){
        loadQuickCheckinModalContents($(this));
      }
    });
  }
});

const quickBookingToggleIn = (element) => {
  var booking_btn = $(element);
  var booking_id = booking_btn.data('booking-id');

  $('.js-quick-checkin-btn').removeClass('active');
  booking_btn.addClass('active');

  $(".js-quick-checkin-table").addClass('d-none');
  $(".js-quick-checkin-table[data-booking="+booking_id+"]").removeClass('d-none');
};

const loadQuickCheckinModalContents = (element) => {
  var loadRemoteUrl = element.data('loadUrl');

  fetch(loadRemoteUrl)
    .then(response => response.text())
    .then(function(html){
      $('#quick_checkin_modal .modal-content').html(html);

      window.initJsAddNewClient(".js-add-new-client");

      $('.selectpicker').selectpicker('refresh');
      $('[data-toggle="tooltip"]').tooltip();
    });
}