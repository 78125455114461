import { Controller } from "stimulus";

export default class extends Controller {

  validateField(event) {
  	var passwordValue = $(event.target).val();
  	var validationContainerElem = $(event.target).closest('.form-group').find('.js-password-validation-fields');

  	if(passwordValue.length == 0) {
      validationContainerElem.hide();
  	} else {
      validationContainerElem.show();
    }

    this.validateMinimumLength(validationContainerElem, passwordValue);
    this.validateAtLeastOneLowerCharacter(validationContainerElem, passwordValue);
    this.validateAtLeastOneUpperCharacter(validationContainerElem, passwordValue);
    this.validateAtLeastOneSpecialCharacter(validationContainerElem, passwordValue);
  }

  validateMinimumLength(validationContainerElem, passwordValue) {
    var validContent = validationContainerElem.find('.js-minimum-requirement .js-valid');
    var invalidContent = validationContainerElem.find('.js-minimum-requirement .js-invalid');

    if(passwordValue.length >= 8) {
      validContent.show();
      invalidContent.hide();
    } else {
      validContent.hide();
      invalidContent.show();
    }
  }

  validateAtLeastOneLowerCharacter(validationContainerElem, passwordValue) {
    var validContent = validationContainerElem.find('.js-lower-requirement .js-valid');
    var invalidContent = validationContainerElem.find('.js-lower-requirement .js-invalid');

    if(passwordValue.match(/[a-z]/g)) {
      validContent.show();
      invalidContent.hide();
    } else {
      validContent.hide();
      invalidContent.show();
    }
  }

  validateAtLeastOneUpperCharacter(validationContainerElem, passwordValue) {
    var validContent = validationContainerElem.find('.js-uppercase-requirement .js-valid');
    var invalidContent = validationContainerElem.find('.js-uppercase-requirement .js-invalid');

    if(passwordValue.match(/[A-Z]/g)) {
      validContent.show();
      invalidContent.hide();
    } else {
      validContent.hide();
      invalidContent.show();
    }
  }

  validateAtLeastOneSpecialCharacter(validationContainerElem, passwordValue) {
    var validContent = validationContainerElem.find('.js-special-requirement .js-valid');
    var invalidContent = validationContainerElem.find('.js-special-requirement .js-invalid');

    if(passwordValue.match(/[0-9!@#$%^&*(),.?":{}|<>]/g)) {
      validContent.show();
      invalidContent.hide();
    } else {
      validContent.hide();
      invalidContent.show();
    }
  }

}