import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.resetTimer();

    var resetTimer = this.resetTimer;

    // When there is activity on the page - reset
    $('body').on('mousemove keydown scroll click focus', function(){
      resetTimer();
    })
  }

  resetTimer() {
    var timeoutDuration = 600000; // 10 Minutes
    // var timeoutDuration = 30000; // 30 Seconds

    if(window.inactiveTimeout !== undefined){
      window.inactiveTimeout = clearTimeout(window.inactiveTimeout);
    }

    var modalTimeoutWarning = function() {
      $('#session-timeout-warning-modal').modal();
      window.modalTimeoutWarningIsEnabled = true;
      var start = Date.now();

      (function timeoutWarningTimer(){
        var timeDiff = Date.now()-start,ns=(((300000-timeDiff)/1000)>>0),m=(ns/60)>>0,s=ns-m*60;
        $('#session-timeout-warning-modal .count-down-timer').html(m+':'+((''+s).length>1?'':'0')+s);

        if( timeDiff > (300000)){
          if(window.modalTimeoutWarningIsEnabled) {
            window.location = $('#session-timeout-warning-modal').data('redirectUrl');
          }
        } else {
          setTimeout(timeoutWarningTimer, 1000);

          if(window.inactiveTimeout !== undefined){
            window.inactiveTimeout = clearTimeout(window.inactiveTimeout);
          }
        }
      })();
    }

    window.inactiveTimeout = setTimeout(modalTimeoutWarning, timeoutDuration);
  }

  restartTimeout(){
    window.modalTimeoutWarningIsEnabled = false;
    $('#session-timeout-warning-modal').modal('hide');
    this.resetTimer();
  }
}
