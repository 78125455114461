import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = {
    countdown: Boolean
  }

  initialize() {
    this.update = this.update.bind(this);
  }

  connect() {
    this.update()
    this.inputTarget.addEventListener('input', this.update);
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.update);
  }

  update() {
    this.counterTarget.innerHTML = this.count();
  }

  count() {
    var value = this.inputTarget.value.length;

    if (this.hasCountdownValue) {
      value = Math.max(this.maxLength - value, 0);
    }

    return value;
  }

  maxLength() {
    return this.inputTarget.maxLength;
  }
}