$(document).on("turbolinks:load", function () {

  if ($('.js-icon-picker').is('*')) {
    $('.js-icon-picker button.js-icon-selector').on('click', function(e){
      e.preventDefault();
      
      var fa_icon = $(this).data('icon');
      var menu = $(this).parents('.js-icon-picker');
      var display_icon = menu.find('.dropdown-toggle i');

      display_icon.removeClass();
      display_icon.addClass('fas');
      display_icon.addClass('fa-'+fa_icon);
      menu.find('input').val(fa_icon);
    });
  }

});
