$(document).on("turbolinks:load", function () {
  if ($('#js-service-form').is('*')) {

    $('.js-service-type').on('click', function() {
      var type = $(this).data('type');
      var showGroup = false;
      var showDuration = true;
      var toggleMultiClient = false;

      switch (type) {
          case 'training':
            showGroup = true;
            break;
          case 'boarding':
            showGroup = false;
            showDuration = false;
            break;
          case 'daycare':
            toggleMultiClient = true;
            break;
          case 'other':
            showGroup = true;
            break;
      }

      if (showGroup || toggleMultiClient) {
        if (toggleMultiClient) {
          $('#js-multi-client input, #js-capacity input').prop('disabled', false);
          $('#js-multi-client input').prop('checked', true);
          $('#js-multi-client').slideUp('fast');
          $('#js-capacity').slideDown('fast');
        } else {
          $('#js-multi-client input, #js-capacity input').prop('disabled', false);
          $('#js-multi-client input').prop('checked', false);
          $('#js-multi-client').slideDown('fast');
          $('#js-capacity').slideUp('fast');  
        }
      } else {
        $('#js-multi-client, #js-capacity').slideUp('fast');
        $('#js-multi-client input, #js-capacity input').prop('disabled', true);
      }

      if (showDuration) {
        $('#js-time-duration').removeClass('d-none');
        $('#js-time-checkin').addClass('d-none');

        $('#js-time-duration input').prop('disabled', false);
        $('#js-time-checkin input').prop('disabled', true);

      } else {
        $('#js-time-checkin').removeClass('d-none');
        $('#js-time-duration').addClass('d-none');

        $('#js-time-duration input').prop('disabled', true);
        $('#js-time-checkin input').prop('disabled', false);
      }
    });

  }
});
