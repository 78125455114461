import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
  	this.setupCustomDateRangePicker();
  }

  setupCustomDateRangePicker() {
    var mySchedule = this.element.dataset.mySchedule === 'true';
    var locationId = this.element.dataset.locationId;

    $(".js-custom-date-range").flatpickr({
    	mode: "range",
			maxDate: "today",
      onChange: function(selectedDates, dateStr, instance) {
      	if(selectedDates.length == 2) {
	      	var startAt = selectedDates[0];
	      	var endAt = selectedDates[1];

	        window.location = window.location.pathname + "?scope=custom&start_at=" + startAt + "&end_at=" + endAt;
      	}
      }
    });
  }

}