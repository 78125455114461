onmount = require("onmount");


window.initJsAddNewClient = function (elem) {
  $(elem).on("change", function () {
    var $this = $(this);
    var selected_client = $("option:selected", $(".js-add-new-client:visible"));
    var select_value = selected_client.val();

    var index = select_value.indexOf("|"); // Gets the first index where a space occours
    var client_id = select_value.substr(0, index); // Gets the first part
    var pet_id = select_value.substr(index + 1); // Gets the text part
    var booking_id = $this.data("booking-id");
    $this.val("default");

    // Shortcircuit exit here due to livesearch setting the selected value to nothing when rending the search form
    if (
      pet_id == null ||
      pet_id == "" ||
      client_id == null ||
      client_id == ""
    ) {
      return;
    }

    $.ajax({
      url: "/business/bookings/render_new_group_client.js",
      type: "GET",
      data: { client_id: client_id, booking_id: booking_id, pet_id: pet_id },
      dataType: "script",
      success: () => {
        $(".js-no-clients:visible", ".js-group-checkin-body:visible").hide();
        selected_client.attr("disabled", "disabled");
        // TODO: add checkmark to option element
        // selected_client.data("content", "✔️ " + selected_client.text());
        $this.selectpicker("refresh");
        onmount();
      },
    });
  });

  // data: {live_search: true, booking_id: @booking.id, live_search_placeholder: "Add a Customer"}, title: "Add a Customer" }
  $(elem)
    .selectpicker({ liveSearch: true, liveSearchPlaceholder: "Add a Customer" })
    .ajaxSelectPicker({
      ajax: {
        url: "/business/search/pet",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: function () {
          var params = {
            q: "{{{q}}}",
          };
          // This is how to add more data to params if you so desire. In the
          // example the GroupID parameter is added so the GET request will be similar to
          // ?q=<q>&GroupID=<assigned-value>
          //
          //if (gModel.selectedGroup().hasOwnProperty("ContactGroupID")) {
          //  params.GroupID = gModel.selectedGroup().ContactGroupID;
          //}
          return params;
        },
      },
      clearOnEmpty: false,
      emptyRequest: true,
      cache: false,
      preserveSelected: false,
    });
};

onmount(".js-trash-a-client", function () {
  $(this).on("click", function () {
    $(this).closest(".js-client-row").remove();
  });
});

onmount("#js-add-new-client", function () {
  window.initJsAddNewClient("#js-add-new-client");
});
