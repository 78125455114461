import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var initialTaxRate = $(this.element).data('initialTaxRate');
    var initialTaxDescription = $(this.element).data('initialTaxDescription');
    var dirtySubmitBtn = $(this.element).find('.js-btn-submit-dirty');

    $(this.element).find('.js-tax-rate').on('change', function(){
      if(initialTaxRate != $(this).val()){
        dirtySubmitBtn.show();
      } else {
        dirtySubmitBtn.hide();
      }
    });

    $(this.element).find('.js-tax-description').on('change keydown keyup', function(){
      if(initialTaxDescription != $(this).val()){
        dirtySubmitBtn.show();
      } else {
        dirtySubmitBtn.hide();
      }
    });
  }

  openTaxSettingsModal(event){
    event.preventDefault();

    $('#tax-settings-modal').modal();
    $('#tax-settings-modal .modal-dialog-loading').show();
    $('#tax-settings-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#tax-settings-modal .modal-dialog-loading').hide();
        $('#tax-settings-modal .modal-dialog-loaded').show();
        $('#tax-settings-modal .modal-dialog-loaded').html(html);

        $('[data-toggle="tooltip"]').tooltip();

        $(".js-toggler-toggle-show-hide-target").each(function() {
          var targetElem = $($(this).data('toggleTarget'));
          $(this).on("click", function () {
            targetElem.toggle();
          });
        });
      });
  }

}