import { Controller } from "stimulus";

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export default class extends Controller {
  static targets = ["client", "clearButton"];

  connect(obj) {
    // console.log("made it!!");
    this.inputChanged(null);
  }

  // event: Event
  inputChanged(event) {
    // console.log("Calculating inputChanged");
    let count = 0;
    this.clientTargets.forEach((item) => {
      count = count + 1;
    });
    // console.log(`Found count=${count}`);

    if (count <= 1) {
      this.clearButtonTargets.forEach((item) => {
        item.classList.add("d-none");
      });
    }
  }

  clearClick(event) {
    // console.log("Clear click clicking!");
    var elem = $(event.target).closest(".js-remove-element")[0];
    // console.log(elem);
    removeElement(elem);

    this.inputChanged(null);
  }
}
