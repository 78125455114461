$(document).on("turbolinks:load", function () {

  if ($('.message-thread-list').is('*')) {
    $('.message-thread-list a.js-message-link').on('click', function(e){
      e.preventDefault();

      var thread_id = $(this).data('thread-id');
      var source = $(this).data('source');

      $('.message-thread-list li').removeClass('active');
      $(this).parent('li').addClass('active');

      $('.js-message-threads .js-message-thread').addClass('d-none');
      $('#js-spinner').removeClass('d-none');

      if ($(window).width() < 575) {
        $('.js-message-threads').addClass('rise-for-mobile');
      }

      loadMessage(thread_id, source);
    });

    $('.js-unread-btn').on('click', function(){
      if ($(this).data('state') == 'unread') {
        updateReadAt($(this).data('thread'));
      } else {
        markUnread(this);
      }
    });

    $('.js-important-btn').on('click', function(){
      toggleImportant($(this).data('thread'));
    })

    $('.js-important-btn').on('mouseenter mouseleave', function(){
      toggleImportantIcon(this);
    })

    changeMailbox();
    loadFirstMessage();
  }

  changeMessageType();
});


function loadMessage(thread_id, source) {
  if (thread_id) {
    $.ajax({
      url: "/business/message_threads/load_message_thread.js",
      type: "GET",
      beforeSend: function(xhr){
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      data: { message_thread_id: thread_id, source: source },
      dataType: 'script',
      success: function(){
        $('.js-exit-mail').on('click', function(){
          $('.js-message-threads').removeClass('rise-for-mobile');
        });

        $('.js-unread-btn').on('click', function(){
          if ($(this).data('state') == 'unread') {
            updateReadAt($(this).data('thread'));
          } else {
            markUnread(this);
          }
        })

        $('[data-toggle="tooltip"]').tooltip();

        thread = $('#thread_'+thread_id+' .message-thread');
        thread.scrollTop(thread[0].scrollHeight);
        updateReadAt(thread_id);
      }
    });
  }
}

function loadFirstMessage() {
  // Load the first message by default
  if($('#js-thread-target').is(':empty') || $('#js-thread-target .js-empty-box').is(':visible')) {
    first_link = $('a.js-message-link:visible:first');
    first_link.parent('li').addClass('active');
  
    loadMessage(first_link.data('thread-id'), first_link.data('source'));  
  }
}

function updateReadAt(thread_id) {
  if (thread_id) {
    $.ajax({
      url: "/business/message_threads/update_read_at",
      type: "POST",
      beforeSend: function(xhr){
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      data: { message_thread_id: thread_id },
      dataType: 'json',
      success: (response) => {
        var unreadButton = $('button.js-unread-btn[data-thread="'+thread_id+'"]')
        var unreadCount = response.unread_count;

        unreadButton.data('state', 'read');
        var icon = unreadButton.find('i');
        icon.attr('data-original-title', 'Mark unread');
        icon.removeClass('fa-mailbox');
        icon.addClass('fa-mailbox-flag-up');
        icon.tooltip();

        $('.js-message-link[data-thread-id="' + thread_id + '"]').closest('li').removeClass('unread');
        $('#js-thread-'+thread_id).removeClass('unread');

        if ( unreadCount > 0) {
          $('.unread-messages-badge span').html(unreadCount);
        } else {
          $('.unread-messages-badge').remove();
        }
      }
    });

  }
}

function markUnread(button) {
  var thread_id = $(button).data('thread');

  $.ajax({
    url: "/business/message_threads/mark_thread_unread",
    type: "POST",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: { message_thread_id: thread_id },
    dataType: 'json',
    success: function () {

      $(button).data('state', 'unread');

      var icon = $(button).find('i');
      icon.attr('data-original-title', 'Mark read');
      icon.removeClass('fa-mailbox-flag-up');
      icon.addClass('fa-mailbox');
      icon.tooltip();

      $('.js-message-link[data-thread-id="' + thread_id + '"]').closest('li').addClass('unread');
    }
  });
}

function toggleImportant(thread_id) {
  if (thread_id) {
    $.ajax({
      url: "/business/message_threads/toggle_important.js",
      type: "POST",
      beforeSend: function(xhr){
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      data: { message_thread_id: thread_id },
      dataType: 'json',
      success: function () {
        var important_button = $('button.js-important-btn[data-thread="'+thread_id+'"]');
        var important_icon = $('.js-important-icon[data-thread="'+thread_id+'"]');
        var message_type_icons = $('.js-message-type-icons[data-thread="'+thread_id+'"]');

        if (important_button.data('important')) {
          important_button.data('important', false);
          important_icon.addClass('d-none');
          message_type_icons.removeClass('d-none');
          important_button.find('i').removeClass('text-success');
          important_button.find('i').addClass('text-muted');
        } else {
          important_button.data('important', true);
          message_type_icons.addClass('d-none');
          important_icon.removeClass('d-none');
          important_button.find('i').removeClass('text-muted');
          important_button.find('i').addClass('text-success');
        }
      }
    });
  }
}

function toggleImportantIcon(icon) {
  var fa_icon = $(icon).find('i');
  if (fa_icon.hasClass('far')) {
    fa_icon.removeClass('far');
    fa_icon.addClass('fas');
  } else {
    fa_icon.removeClass('fas');
    fa_icon.addClass('far');
  }
}

function changeMailbox() {
  $('.js-mailbox-button').on('click', function(){
    var type = $(this).data('mailbox');

    if (type == 'inbox') {
      var alternates = ['outbox', 'archived'];
    } else if (type == 'outbox') {
      var alternates = ['inbox', 'archived'];
    } else {
      var alternates = ['inbox', 'outbox'];
    }

    $('.js-mailbox-button').removeClass('active');
    $(this).addClass('active');
    $('.message-thread-list li').removeClass('active');

    alternates.forEach((alternate) => {
      $('#'+alternate).addClass('d-none');
      $('#paginate-'+alternate).addClass('d-none');
    })

    $('#js-thread-target').empty();  
    $('#'+type).removeClass('d-none');
    $('#paginate-'+type).removeClass('d-none');

    loadFirstMessage();
  });
}

function changeMessageType() {

  $('body').on('click', '.js-sms-button', function (event) {
    $('#sms-warning').removeClass('d-none');
    $('.js-email-inputs').fadeOut('fast', function(){
      $('.js-email-inputs input[type=text], .js-email-inputs textarea').prop("disabled", true);
      $('.js-sms-inputs input, .js-sms-inputs textarea').prop("disabled", false);
      $('.js-sms-inputs').fadeIn('fast');
    });
  });

  $('body').on('click', '.js-email-button', function (event) {
    $('#sms-warning').addClass('d-none');
    $('.js-sms-inputs').fadeOut('fast', function(){
      $('.js-email-inputs').fadeIn('fast');

      $('.js-sms-inputs input, .js-sms-inputs textarea').prop("disabled", true);
      $('.js-email-inputs input[type=text], .js-email-inputs textarea').prop("disabled", false);
    });
  });

  $('body').on('submit', '.js-message-form-validation', function(event){
    var trixEditor = $(this).find('trix-editor:visible');
    var smsEditor = $(this).find('.js-sms-inputs textarea:visible');
    var submitBtn = $(this).find('[type=submit]');

    if(trixEditor != undefined && trixEditor.length > 0) {
      if(trixEditor.val() == '') {
        trixEditor.prop('style', 'border: 1px solid red;');

        setTimeout(function() {
          submitBtn.prop('disabled', false);
          submitBtn.html('<i class="fas fa-paper-plane mr-2"></i> Send Message');
        }, 25);

        event.preventDefault();
      } else {
        trixEditor.prop('style', '');
      }
    }else if (smsEditor != undefined && smsEditor.length > 0) {
     if(smsEditor.val() == '') {
        smsEditor.prop('style', 'border: 1px solid red;');

        setTimeout(function() {
          submitBtn.prop('disabled', false);
          submitBtn.html('<i class="fas fa-paper-plane mr-2"></i> Send Message');
        }, 25);

        event.preventDefault();
      } else {
        smsEditor.prop('style', '');
      }
    }
  })
}
