$(document).on("turbolinks:load", function () {
  if ($('.js-get-chart-total').is('*')) {
    $('.js-get-chart-total').each(function(index, element){
      getChartTotal(element);
    });

    setInterval( function(){
      $('.js-get-chart-total').each(function(index, element){
        var chartType = $(element).data('chart-type');
        console.log("Updating totals for: "+chartType);
        getChartTotal(element);
      });
    }, 60000);
  }

  if ($('.js-table-placeholder').is('*')) {
    $('.js-table-placeholder').each(function(index, element){
      getTableData(element);
    });
  }

});

function getChartTotal(element) {
  var chartType = $(element).data('chart-type');
  var scope = $(element).data('scope');
  var prefix = $(element).data('prefix');
  var suffix = $(element).data('suffix');

  $.ajax({
    url: $(element).data('url'),
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      scope: scope,
      chart_type: chartType
    },
    dataType: 'json',
    success: (response) => {
      var total = response.total;
      var change = response.change+'%';
      var growth = response.growth;
      var valueElement = $(element).find('.value');
      var badgeElement = $(element).find('.badge');

      if (prefix) {
        valueElement.html(prefix+total);
      } else if (suffix) {
        valueElement.html(total+suffix);
      } else {
        valueElement.html(total);
      }

      badgeElement.html(change);

      if (growth == 'none' || growth == null) {
        badgeElement.remove();
      } else if (growth == true) {
        badgeElement.removeClass('badge-warning');
        badgeElement.addClass('badge-success');
      } else if (growth == false) {
        badgeElement.removeClass('badge-success');
        badgeElement.addClass('badge-warning');
      }
    },
    error: () => {
      console.log("Failed to load totals for chart: "+chartType);
      $(element).html('');
      $(element).removeClass('js-get-chart-total');
    }
  });
}

function getTableData(element) {
  var dataType = $(element).data('type');
  var dataSubType = $(element).data('sub-type');
  var scope = $(element).data('scope');
  var startAt = $(element).data('start-at');
  var endAt = $(element).data('end-at');

  $.ajax({
    url: $(element).data('url'),
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      scope: scope,
      data_type: dataType,
      data_sub_type: dataSubType,
      start_at: startAt,
      end_at: endAt
    },
    dataType: 'script',
    error: () => {
      console.log("Failed to load table: "+dataType);
      $(element).html('');
    }
  });
}
