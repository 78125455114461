$(document).on("turbolinks:before-cache", function () {
  if ($(".flatpickr-calendar").is("*")) {
    $(".flatpickr-calendar").remove();
  }

  if ($(".ui-timepicker-container").is("*")) {
    $(".ui-timepicker-container").remove();
  }
});

$(document).on("turbolinks:load", function () {
  if ($.TimePicker._instance) {
    $.TimePicker._instance = new $.TimePicker();
  }

  window.loadFlatpickr();
  window.loadTimepicker();
});

window.loadFlatpickr = function () {
  flatpickr(".datepicker", {
    autoclose: true,
    inline: true,
    monthSelectorType: "static",
    dateFormat: "D d M Y",
    allowInput: true,
  });

  // This affects the recurring date select in the new and edit booking form
  flatpickr(".flatpickr", {
    autoclose: true,
    monthSelectorType: "static",
    dateFormat: "Y-m-d",
    allowInput: false,
  });

  // Use this when you want to use html's required="required" attribute
  // https://stackoverflow.com/questions/45189710/make-flatpickr-input-required
  flatpickr(".flatpickr-required", {
    autoclose: true,
    monthSelectorType: "static",
    dateFormat: "Y-m-d",
    allowInput: true,
    onReady: function (selectedDates, dateStr, instance) {
      let el = instance.element;
      function preventInput(event) {
        event.preventDefault();
        return false;
      }
      el.onkeypress = el.onkeydown = el.onkeyup = preventInput; // disable key events
      el.onpaste = preventInput; // disable pasting using mouse context menu

      el.style.caretColor = "transparent"; // hide blinking cursor
      el.style.cursor = "pointer"; // override cursor hover type text
      el.style.color = "#585858"; // prevent text color change on focus
      el.style.backgroundColor = "#f7f7f7"; // prevent bg color change on focus
    },
  });

  flatpickr(".flatpickr-wrap", {
    autoclose: true,
    monthSelectorType: "static",
    dateFormat: "Y-m-d",
    allowInput: false,
    wrap: true,
  });
};

window.loadTimepicker = function () {
  // this function may be called multiple times dynamically
  // before we load it we want to destroy other bindings so we don't fire the change events multiple times
  $(".timepicker").timepicker("destroy");

  $(".timepicker").each(function (i, element) {
    var interval = $(this).data("interval") || "15";
    var minTime = $(this).data("mintime");
    var maxTime = $(this).data("maxtime");
    var defaultTime = $(this).val();
    var dispatchChangeEvent = $(this).data("dispatchChangeEvent") || false;

    $(element).timepicker({
      interval: interval,
      dynamic: false,
      minTime: minTime,
      maxTime: maxTime,
      defaultTime: defaultTime || minTime,
      change: function (time) {
        // major hacks
        if (dispatchChangeEvent) {
          if (element.form && element.form.stimulus_controller) {
            element.form.stimulus_controller.inputChanged(null);
          }
          if (element.stimulus_controller) {
            element.stimulus_controller.inputChanged(null);
          }
        }

        if ($(element).data("prev-time") != time.toString()) {
          $(element).data("prev-time", time.toString());

          if ($(this).parents(".js-remote-form").is("*")) {
            $(this)
              .parents(".js-remote-form")[0]
              .dispatchEvent(new Event("change"));
          }

          if ($(this).data("adjust-min")) {
            active_timepicker = $(this);
            var target_id = $(this).data("adjust-min");

            $(target_id).timepicker(
              "option",
              "minTime",
              active_timepicker.val()
            );
            $(target_id).data("min", active_timepicker.val());
          }

          if ($(this).data("adjust-max")) {
            active_timepicker = $(this);
            var target_id = $(this).data("adjust-max");

            $(target_id).timepicker(
              "option",
              "maxTime",
              active_timepicker.val()
            );
            $(target_id).data("max", active_timepicker.val());
          }

          $(element).trigger('click');
        }
      },
    });
  });
};
