import Chart from 'chart.js/auto';

$(document).on("turbolinks:load", function () {
  if ($('.sparkline, .line-chart, .pie-chart').is('*')) {
    $('.sparkline, .line-chart, .pie-chart').each(function(index, element){
      getChartData(element);
    });
  }

});

function getChartData(element) {
  var url = $(element).data('url');
  var scope = $(element).data('scope');
  var chartType = $(element).data('chart-type');

  $.ajax({
    url: url,
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      scope: scope,
      chart_type: chartType
    },
    dataType: 'json',
    success: (response) => {
      if ($(element).hasClass('sparkline')){
        $(element).parent('.sparkline-container').siblings('.sparkline-error').remove();
        var chart = drawSparkline(element, response);

      } else if ($(element).hasClass('line-chart')) {
        var chart = drawLineChart(element, response);

      } else if ($(element).hasClass('pie-chart')) {
        if (response.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) == 0) {
          var empty_html = '<div class="d-flex flex-column h-100 justify-content-center text-center text-muted"><i class="fas fa-empty-set fa-2x"></i><div class="mt-2">No data to display.</div></div>';
          $(element).parent().html(empty_html);

        } else {
          var chart = drawPieChart(element, response);
        }
      }

      setInterval( function() {
        updateChartData(chart, url, scope, chartType);
      }, 60000);
    },
    error: () => {
      console.log("Failed to load data for chart: "+chartType);
      var error_html = '<div class="d-flex flex-column h-100 justify-content-center text-center text-muted"><i class="fas fa-ban fa-2x"></i><div class="mt-2">Unable to load chart.</div></div>';

      if ($(element).hasClass('sparkline')){
        var parent = $(element).parents('.card-body');
        parent.children('.sparkline-container').remove();
        parent.children('.sparkline-labels').remove();

      } else if ($(element).hasClass('line-chart')) {
        var parent = $(element).parent();
      } else if ($(element).hasClass('pie-chart')) {
        var parent = $(element).parent();
      }
      parent.html(error_html);
    }
  });
}

function updateChartData(chart, url, scope, chartType) {
  $.ajax({
    url: url,
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      scope: scope,
      chart_type: chartType
    },
    dataType: 'json',
    success: (response) => {
      console.log("Data updated: "+chartType);
      chart.data = response;
      chart.update('none');
    },
    error: () => {
      console.log("Failed to update data for chart: "+chartType);
    }
  });
}

function drawSparkline(element, data) {
  var prefix = $(element).data('prefix');
  var suffix = $(element).data('suffix');

  if (!prefix) {
    prefix = '';
  }

  if (!suffix) {
    suffix = '';
  }

  var sparkline = new Chart(element, {
    type: 'line',
    data: data,
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          borderWidth: 2,
          borderColor: ['#cb00ff', '#dee2e6'],
          backgroundColor: ['#cb00ff', '#dee2e6']
        },
        point: {
          radius: 0
        }
      },
      animation: {
        easing: 'easeOutQuart'
      },
      interaction: {
        intersect: false,
        mode: 'index'
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      scales: {
        y: {
          display: false,
          suggestedMin: 0,
          suggestedMax: 100
        },
        x: {
          display: false
        },
        xTop: {
          type: 'category',
          position: 'top',
          display: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              if (context.parsed.y !== null) {
                label += prefix + context.parsed.y.toLocaleString() + suffix;
              }
              return label;
            }
          }
        }
      }
    }
  });
  return sparkline;
}

function drawLineChart(element, data) {
  var prefix = $(element).data('prefix');
  var suffix = $(element).data('suffix');

  if (!prefix) {
    prefix = '';
  }

  if (!suffix) {
    suffix = '';
  }

  var lineChart = new Chart(element, {
    type: 'line',
    data: data,
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          borderWidth: 2,
          borderColor: ['#cb00ff', '#dee2e6'],
          backgroundColor: ['#cb00ff', '#dee2e6']
        },
        point: {
          radius: 0
        }
      },
      animation: {
        easing: 'easeOutQuart'
      },
      interaction: {
        intersect: false,
        mode: 'index'
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      scales: {
        y: {
          display: false,
          suggestedMin: 0,
          suggestedMax: 1000
        },
        x: {
          display: true,
          grid: {
            display: false
          }
        },
        xTop: {
          type: 'category',
          position: 'top',
          display: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              if (context.parsed.y !== null) {
                label += prefix + context.parsed.y.toLocaleString() + suffix;
              }
              return label;
            }
          }
        }
      }
    }
  });
  return lineChart;
}

function drawPieChart(element, data) {
  var prefix = $(element).data('prefix');
  var suffix = $(element).data('suffix');

  if (!prefix) {
    prefix = '';
  }

  if (!suffix) {
    suffix = '';
  }

  var pieChart = new Chart(element, {
    type: 'doughnut',
    data: data,
    options: {
      maintainAspectRatio: false,
      elements: {
        arc: {
          borderColor: ['#6747f7', '#cb00ff', '#ffaa1b', '#00c690', '#0290ee', '#fe3437', '#fe7937'],
          backgroundColor: ['#6747f7', '#cb00ff', '#ffaa1b', '#00c690', '#0290ee', '#fe3437', '#fe7937'],
          borderWidth: 0
        }
      },
      animation: {
        easing: 'easeOutQuart'
      },
      interaction: {
        intersect: false,
        mode: 'index'
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      plugins: {
        legend: {
          display: true,
          position: 'right'
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              if (context.parsed !== null) {
                label += prefix + context.parsed.toLocaleString() + suffix;
              }
              return label;
            }
          }
        }
      }
    }
  });
  return pieChart;
}
