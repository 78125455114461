import { Controller } from "stimulus";

export default class extends Controller {

  formLocationChanged(event) {
    var locationIdSelected = $(event.target).val();
    $('.service-input-container input[type=checkbox]:checked').click(); // Deselect all locations

    if(locationIdSelected === "") {
      $('.service-input-container').show();
    } else {
      $('.service-input-container').hide();
      $('.service-input-container[data-location-id=' + locationIdSelected + ']').show();
    }
  }

}