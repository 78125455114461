// Docs: https://pqina.nl/filepond/docs/

import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileMetadata,
  FilePondPluginFileRename
);

$(document).on("turbolinks:load", function () {

  const pond = new FilePond.create(document.querySelector("input.filepond"), {
    maxFiles: 1,
    allowPaste: false,
    maxFileSize: "2000MB",
    credits: false,
    acceptedFileTypes: ['video/*'],
    labelFileTypeNotAllowed: 'Please select a video file to upload.',
    fileRenameFunction: (file) => {
      return `${file.name.replace(/\s+/g, '_')}`;
    },
    server: {
      timeout: 99999999,
      process: function (fieldName, file, metadata, load, error, progress, abort) {
        var filepondRequest = new XMLHttpRequest();
        var filepondFormData = new FormData();
        var s3params = $.parseJSON(metadata.formData);

        // Check for when the upload succeeds, then do stuff
        filepondRequest.onreadystatechange = function(){
          if (this.readyState == 4 && this.status == 201) {
            var s3key = $(this.response).find("Key").text();
            $.ajax({
              url: metadata.updateLesson,
              type: "POST",
              beforeSend: function(xhr) {
                xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"))
              },
              data: {s3_video_tag: s3key.replace('.'+metadata.fileInfo.fileExtension,'')},
              dataType: 'script',
              success: function(){
                location.reload();
                return;
              }
            });
          }
        }

        for (var key in s3params) {
          filepondFormData.append(key, s3params[key]);
        }
        filepondFormData.append('file', file);

        filepondRequest.upload.onprogress = function (e) {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        filepondRequest.open("POST", metadata.url);

        filepondRequest.onload = function () {
            load(s3params[key]);
        };

        filepondRequest.send(filepondFormData);

      }
    }
  });

  pond.on("addfile", function (error, file) {
    if (error) {
      return;
    }
    file.setMetadata('fileInfo', {
      fileExtension: file.fileExtension
    });
  });

});
