$(document).on("turbolinks:load", function () {
  if ($('#js-notifications').is('*')) {
    if ($('#js-notifications .loader').is('*')) {
      $('#js-notifications').on('mouseover', function(){
        $.get({
          url: $('#js-notifications').data('url'),
          type: "GET",
          beforeSend: function(xhr){
            xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
          },
          dataType: "script",
          success: function() {
            $('#js-notifications').unbind('mouseover');
            // Bind read_at call to unread notifications
            setTimeout(function(){
              bindReadAt();
            },
            1000);
          }
        });
      });  
    }
  }

  if ($('.js-unread-notification').is('*')) {
    bindReadAt();
  }

  if ($('.js-filter-notifications-form').is('*')){
    filterForm();
  }
});

function bindReadAt() {
  $('.js-unread-notification').on('mouseover', function(){
    var notification = $(this);
    $.get({
      url: notification.data('url'),
      type: "POST",
      beforeSend: function(xhr){
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      dataType: "json",
      data: { notification_id: notification.data('notification') },
      success: function(){
        notification.removeClass('table-primary');
        notification.unbind('mouseover');
      }
    });
  });
}

function filterForm(){
  console.log('loading')
  $('.js-filter-notifications-form').on('change', function(){
    console.log('submitting')
    $(this).submit();
  })
}