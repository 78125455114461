import { Controller } from "stimulus";

export default class extends Controller {

  nameAlertCheck(event) {
  	var inputVal = $(event.target).val().toLowerCase();

  	if(inputVal.includes('package')) {
  		$('.js-package-warning').show();
  	} else {
  		$('.js-package-warning').hide();
  	}

  	if(inputVal.includes('addon') || inputVal.includes('add-on')) {
  		$('.js-addon-warning').show();
  	} else {
  		$('.js-addon-warning').hide();
  	}
  }

}