import { Controller } from "stimulus";
var onmount = require("onmount");

export default class extends Controller {
  static values = {
    url: String,
    refreshSelectpicker: String,
    renableOnLoadElement: String,
  };

  connect() {
    this.loadRemoteContent();
  }

  loadRemoteContent() {
    var controller = this;

    fetch(this.urlValue)
      .then((response) => response.text())
      .then(function (html) {
        controller.element.innerHTML = html;

        if (controller.renableOnLoadElementValue !== undefined) {
          $(controller.renableOnLoadElementValue).prop("disabled", false);
          $(controller.renableOnLoadElementValue)
            .find(".loader")
            .addClass("d-none");
        }

        $('[data-toggle="tooltip"]').tooltip({ sanitize: false });

        onmount();

        if(window.setupJSRemoteForm !== undefined ) {
          window.setupJSRemoteForm();
        }

        if(window.setupJSRemoteForm !== undefined ) {
          window.setupJSRemoteForm();
        }

        if (controller.refreshSelectpickerValue !== "disabled") {
          $(".selectpicker").selectpicker("refresh");
        }

        if ($(".js-copy-to-keyboard").is("*")) {
          controller.copyToKeyboardJs();
        }

        flatpickr(".flatpickr", {
          autoclose: true,
          monthSelectorType: "static",
          dateFormat: "Y-m-d",
          allowInput: false,
        });

      });
  }

  copyToKeyboardJs() {
    $(".js-btn-copy-to-clipboard").click(function () {
      var clipboardContents = $(this)
        .closest(".js-copy-to-clipboard-container")
        .find(".js-copy-to-keyboard");
      clipboardContents.click();
      return false;
    });

    $(".js-copy-to-keyboard").click(function () {
      var restoreToHidden = false;

      // In order for the execCommand to work we need the field to be input[type=text]
      // If it's hidden temporarily change it; then retsore it.
      if ($(this).prop("type") == "hidden") {
        $(this).prop("type", "text");
        restoreToHidden = true;
      }

      $(this).select();
      document.execCommand("copy");

      if (restoreToHidden) {
        $(this).prop("type", "hidden");
      }

      var confirmationDiv = $(this)
        .closest(".js-copy-to-clipboard-container")
        .find(".js-copied-to-keyboard-confirmation");
      confirmationDiv.fadeIn();

      setTimeout(function () {
        confirmationDiv.fadeOut();
        return;
      }, 2750);

      return false;
    });
  }
}
