$(document).on("turbolinks:load", function () {
  if ($('.js-publish-series').is('*')) {
    $('.js-publish-series').on('click.published', function(){
      var seriesId = $(this).parents('.js-remote-form').data('series');
      var parentCard = $('#js-series-card-'+seriesId);

      if (parentCard.hasClass('border-success')) {
        parentCard.removeClass('border-success');
        parentCard.addClass('border-light');
      } else {
        parentCard.removeClass('border-light');
        parentCard.addClass('border-success');
      }

    });

    $('.js-online-payments-option').on('click', function(){
      var clickedOption = $(this);
      var form = clickedOption.parents('form');

      form.find('input:checked').each(function(){
        if( $(this).attr('id') != clickedOption.attr('id')) {
          $(this).prop('checked', false);
          if( $(this).hasClass('js-toggler-toggle-show-hide-target')){
            $($(this).data('toggleTarget')).toggle();
          }
        }
      });
    });

  }
});
