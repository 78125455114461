$(document).on("turbolinks:load", function () {

  if ($('#js-indefinite-switch').is('*')){
    $('#js-indefinite-switch').on('change', function(){
      if($('#js-indefinite-switch').is(':checked')) {
        $('#js-term-row').hide();
      } else {
        $('#js-term-row').show();
      }
      $('#js-term-row input').val('');
    });
  }

  if ($('#js-term-text').is('*')) {
    var freq_select = $('#js-frequency-select select');

    replaceText(freq_select);

    freq_select.change(function(){
      replaceText(freq_select);
    });

  }

});

function replaceText(freq_select) {
  var term_text = $('#js-term-text');
  var text = '';

  $('#js-term-row').slideDown('fast');
  $('#js-indefinite-row').slideDown('fast');

  if($('#js-indefinite-switch').is(':checked')) {
    $('#js-term-row').hide();
  }

  switch ( freq_select.val() ) {
    case 'one-time':
      $('#js-term-row').slideUp('fast');
      $('#js-indefinite-row').slideUp('fast');
      break;
    case 'weekly':
      text = 'Weeks';
      break;
    case 'monthly':
      text = 'Months';
      break;
    case 'quarterly':
      text = 'Quarters';
      break;
    case 'semi-annual':
      text = 'Halves';
      break;
    case 'yearly':
      text = 'Years';
  }

  term_text.text(text);
}

onmount('select.js-select-membership-service', function () {
  $(this).on('change', function(){
    var id = $(this).find(':selected').val();
    var service_id = $(this).parents('.js-delete-target').find("input[name*='service_id']");
    var series_id = $(this).parents('.js-delete-target').find("input[name*='series_id']");

    if ( $(this).find(':selected').data('series') ) {
      service_id.val('');
      series_id.val(id.replace('[series]', ''));
    } else {
      service_id.val(id);
      series_id.val('');
    }
  });
});

onmount('select.js-credit-select', function () {
  $(this).on('change', function(){
    var select = $(this).find(':selected');
    var quantity_field = $(this).parents('.js-membership-credit').find('.qty');
    var value_field = $(this).parents('.js-membership-credit').find('.value');
    var addon_id = $(this).parents('.js-membership-credit').find('.addon-id');
    var service_id = $(this).parents('.js-membership-credit').find('.service-id');
    var credit_type = $(this).parents('.js-membership-credit').find('.credit-type');

    if(select.val() == 'cash') {
      addon_id.val('');
      service_id.val('');
      credit_type.val('cash');
      quantity_field.fadeOut('fast', function(){
        quantity_field.prop('disabled', true)
        value_field.find('input').prop('disabled', false)
        value_field.fadeIn('fast')
      });
    } else if(select.data('addon')) {
      addon_id.val(select.val());
      service_id.val('');
      credit_type.val(select.data('type'));
      value_field.fadeOut('fast', function(){
        value_field.find('input').prop('disabled', true);
        quantity_field.prop('disabled', false);
        quantity_field.fadeIn('fast');
      });
    } else {
      addon_id.val('');
      service_id.val(select.val());
      credit_type.val(select.data('type'));
      value_field.fadeOut('fast', function(){
        value_field.find('input').prop('disabled', true);
        quantity_field.prop('disabled', false);
        quantity_field.fadeIn('fast');
      });
    }
  });

  $('[data-toggle="tooltip"]').tooltip()
});

onmount("select.js-frequency-select", function () {

  $(this).on('change', function(){

    var select = $(this).find(':selected');
    var expireSwitch = $(this).parents('.js-membership-credit').find('.js-expire-switch');

    if(select.val() == 'one-time') {
      expireSwitch.hide();
    } else {
      expireSwitch.show();
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
});
